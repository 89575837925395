import React, { Fragment, useEffect, useState } from "react";
// import CKEditors from "react-ckeditor-component";
import {
  Button,
  Form,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  yupProductValidation,
  productSelectBoxValidtion,
  // productCodeValidation,
} from "../../utils/authValidation";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import prodImg from "../../assets/images/dashboard/product/20.jpg";
// import { queryString } from "../../utils/queryString";
// import { validateImage } from "../../utils/multiuseFunctions";
const ProductForm = ({
  payload,
  setPayload,
  sizeArray,
  productNatureArray,
  colorArray,
  data,
}) => {
  ////
  const { _id } = payload || {};
  // const { id } = queryString();
  const result = useSelector((state) => state.admin);
  const [previewBannerImage, setPreviewBannerImage] = useState([]);
  const [editName, setEditName] = useState("");
  const {
    selectedCategory,
    // selectePartnerList,
    // selectebrandList,
    selectedRarity,
    loginAdmin,
  } = result || {};
  const { role } = loginAdmin || [];

  //  console.log('selectedCategory==', selectedCategory, 'selectedRarity', selectedRarity,)
  let history = useHistory();
  const {
    register,
    //setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: payload || {},
    resolver: yupResolver(yupProductValidation(_id)),
  });
  const [editData, setEditData] = useState();
  // const [selectedFile, setSelectedFile] = useState()
  // const [preview, setPreview] = useState(payload.image || '')
  const [customErrors, setCustomErrors] = useState({});
  // const [image, setImage] = useState(payload.image || []);
  // const [checkStatus, setCheckStatus] = useState(false);
  const [codeError, setCodeError] = useState({});
  // const [istotal, setIsTotal] = useState(false);
  const [ddd] = useState();
  const notValidateFields = [
    // "brandId",
    "partnerId",
    "categoryId",
    "rarityLevel",
    // "productNature",
  ];
  const onSubmitHandler = async (data) => {
    try {
      console.log("payloadpayload=>", payload?.color);
      const { custErrors, isValid } = productSelectBoxValidtion(
        payload,
        notValidateFields
      );

      payload.colorname = payload?.color?.split(",");
      payload.sizename = payload?.size?.split(",");

      // console.log('payload======>>>>>', payload);
      setCustomErrors(custErrors);
      if (
        Object.keys(errors).length === 0 &&
        isValid &&
        !codeError?.productCode
      ) {
        // console.lo0g('(errors).length//////', (errors).length)
        if (_id) {
          const response = await adminInstance().put(
            `/api/admin/updateProduct/${_id}`,
            { data, payload }
          );
          const { code, msg } = response.data;
          setEditData({ ...editData, isUpdate: true });
          if (code === 200) {
            toast.success(msg);
            reset();
            history.push("/products/list_product");
          } else {
            toast.error(msg);
          }
        } else {
          const response = await adminInstance().post(
            "/api/admin/createProduct",
            { data, payload }
          );
          const { code, msg } = response.data;
          if (code === 200) {
            toast.success(msg);
            reset();
            history.push("/products/list_product");
          } else {
            toast.error(msg);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const IncrementItem = (value, name) => {
  //   console.log("value===>", value, "name===>", name);
  //   setPayload({ ...payload, [name]: parseInt(value, 10) + 1 });
  // };

  // const DecreaseItem = (value, name) => {
  //   if (value > 0) {
  //     setPayload({ ...payload, [name]: parseInt(value, 10) - 1 });
  //   }
  // };
  const handleOnChange = (event, val) => {
    const { name, value } = event;
    console.log("name", name, value, val);
    if (name === "productCode") {
      setCodeError({});
    }

    if (name === "quickSell" || name === "visibility") {
      setPayload({ ...payload, [name]: val });
    } else if (name === "enableProduct" || name === "visibility") {
      setPayload({ ...payload, [name]: val });
    } else if (name === "checkTrack") {
      const quantVal = 0;
      setPayload({ ...payload, [name]: !val, trackQuantity: quantVal });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };
  const handleSelectChange = (Opt, name) => {
    let newPayload = { ...payload };
    if (
      name === "categoryId" ||
      name === "capacity" ||
      name === "size" ||
      name === "color"
    ) {
      // console.log('conditionalRendering', Opt, name);
      const datas = Array.isArray(Opt) ? Opt.map((x) => x.value) : [];
      newPayload[name] = datas;
      // setPayload({ ...payload, [name]: datas });
      setPayload(newPayload);
    } else {
      newPayload[name] = Opt.value;
      setPayload(newPayload);
      // setPayload({ ...payload, [name]: Opt.value });
    }
    const { custErrors } = productSelectBoxValidtion(
      newPayload,
      notValidateFields
    );
    setCustomErrors(custErrors);
  };

  // const handleProductCode = async (event) => {
  //   const { value } = event;
  //   // console.log('name', name, value);
  //   if (value && !id) {
  //     const data = await productCodeValidation(value);
  //     // console.log('custErrors', data);
  //     setCodeError(data.custErrors);
  //   } else {
  //     setCodeError({});
  //   }
  // };
  // const handleChangePro = (Opt, name) => {
  //   // console.log("Opt==>", Opt, name);

  //   let multiFields = "rarityLevelId" || "name" || "color";
  //   if (name === multiFields) {
  //     // console.log("conditionalRendering", Opt, name);
  //     const datas = Array.isArray(Opt) ? Opt.map((x) => x.value) : [];
  //     setPayload({ ...payload, [name]: datas });
  //   } else {
  //     setPayload({ ...payload, [name]: Opt.value });
  //   }
  // };

  // const [previewImage, setPreviewImage] = useState([]);

  // const uploadFile = async (data) => {
  //   try {
  //     setCheckStatus(true);
  //     const tempArr = [...image];
  //     const formdata = new FormData();
  //     const arr = [...data.target.files];
  //     if (arr.length > 0) {
  //       arr.forEach((el) => {
  //         formdata.append("file", el);
  //       });
  //     }
  //     setPreviewImage([...previewImage, ...data.target.files]);
  //     const response = await adminInstance().post(
  //       "api/admin/uploadProductImage",
  //       formdata
  //     );
  //     console.log("response ===>", response);
  //     const { code, imageArr } = response.data;
  //     if (code === 200) {
  //       imageArr.forEach((element) => tempArr.push(element));
  //       setImage(tempArr);
  //       setPayload({ ...payload, image: tempArr });
  //       setCheckStatus(false);
  //     }
  //   } catch (error) {
  //     setCheckStatus(false);
  //     console.log("error", error);
  //   }
  // };
  // const removePhotos = (e, index) => {
  //   const old = [...image];
  //   old.splice(index, 1);
  //   setImage(old);
  //   setPayload({ ...payload, image: old });
  // };

  // const validateProductImage = (img) => {
  //   const size = img.size;
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.addEventListener("load", (event) => {
  //       let picFile = event.target;
  //       let imgNew = new Image();
  //       imgNew.src = window.URL.createObjectURL(img);
  //       imgNew.addEventListener("load", () => {
  //         let valid = true;
  //         let msg = "";
  //         if (size < 1000000) {
  //           if (imgNew.width <= 600 && imgNew.height <= 600) {
  //             if (imgNew.width === imgNew.height) {
  //               msg = "ok";
  //               valid = true;
  //               resolve(reader.result);
  //             } else {
  //               msg = "Image must be in square format";
  //               valid = false;
  //               reject({ msg, valid });
  //             }
  //           } else {
  //             msg = "Image dimension must be less than or equal to (400 x 400)";
  //             valid = false;
  //             reject({ msg, valid });
  //           }
  //         } else {
  //           msg = "Image size must be less than 1 MB";
  //           valid = false;
  //           reject({ msg, valid });
  //         }
  //       });
  //       imgNew.addEventListener("error", () => {
  //         reject();
  //       });
  //       imgNew.src = picFile.result;
  //     });
  //     reader.readAsDataURL(img);
  //   });
  // };

  const uploadBannerFile = async (data) => {
    try {
      // await validateImage(data.target.files[0]);
      // await validateProductImage(data.target.files[0]);
      const formdata = new FormData();
      formdata.append("file", data.target.files[0]);

      // Update the "No file chosen..." element text with the selected file name
      const noFileText = document.getElementById("noFile");
      if (data.target.files.length > 0) {
        noFileText.textContent = data.target.files[0].name;
      }

      setPreviewBannerImage(data.target.files);
      const response = await adminInstance().post(
        "api/admin/uploadProductImage",
        formdata
      );
      const { code, imageArr } = response.data;
      console.log("imageArr==>", imageArr);
      if (code === 200) {
        setPayload({ ...payload, bannerImage: imageArr[0] });
        // setCheckStatus(false);
      }
    } catch (error) {
      const { msg, valid } = error || {};
      if (!valid) {
        toast.error(msg);
      }
    }
  };

  useEffect(() => {
    const imageName = payload?.bannerImage;
    let name = "";
    if (imageName) {
      name = imageName?.substring(imageName?.lastIndexOf("/") + 1);
      name = name.replace(/_\d+/g, "");
      setEditName(name);
    }
  }, [payload]);

  return (
    <Fragment>
      <Form
        className="needs-validation user-add"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <Row className="product-adding product-adding-page">
          <Col xl="6">
            <Card>
              <CardBody>
                <div className="digital-add needs-validation">
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      <span>*</span> Product Name
                    </Label>
                    <input
                      {...register("name")}
                      className="form-control"
                      type="text"
                      defaultValue={payload.name || ""}
                      onChange={(e) => handleOnChange(e.target)}
                      name="name"
                    />
                    <p className="text-danger">{errors.name?.message}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      <span>*</span> Price
                    </Label>
                    <input
                      {...register("price")}
                      className="form-control"
                      type="number"
                      step="0.0001"
                      defaultValue={payload.price || ""}
                    />
                    <p className="text-danger">{errors.price?.message}</p>
                  </FormGroup>
                  {/* <FormGroup>
										<Label className="col-form-label pt-0">Product Code</Label>
										<input
											value={payload.productCode}
											onChange={(e) => handleOnChange(e.target)}
											onBlur={(e) => handleProductCode(e.target)}
											className="form-control"
											type="text"
											name="productCode"
										/>
										<p className="text-danger">{codeError?.productCode}</p>
									</FormGroup> */}
                  {/* <FormGroup>
                    <Label className='col-form-label'>
                      <span></span> Total Product
                    </Label>
                    <fieldset className='qty-box ml-0'>
                      <div className='input-group bootstrap-touchspin'>
                        <div className='input-group-prepend'>
                          <Button
                            className='btn btn-primary btn-square bootstrap-touchspin-down'
                            type='button'
                            onClick={() =>
                              DecreaseItem(payload.totalProduct, "totalProduct")
                            }>
                            <i className='fa fa-minus'></i>
                          </Button>
                        </div>
                        <div className='input-group-prepend'>
                          <span className='input-group-text bootstrap-touchspin-prefix'></span>
                        </div>
                        <Input
                          className='touchspin form-control'
                          type='number'
                          value={payload.totalProduct || ""}
                          onChange={(e) => handleOnChange(e.target)}
                          name='totalProduct'
                        />
                        <div className='input-group-append'>
                          <span className='input-group-text bootstrap-touchspin-postfix'></span>
                        </div>
                        <div className='input-group-append ml-0'>
                          <Button
                            className='btn btn-primary btn-square bootstrap-touchspin-up'
                            type='button'
                            onClick={() =>
                              IncrementItem(
                                payload.totalProduct,
                                "totalProduct"
                              )
                            }>
                            <i className='fa fa-plus'></i>
                          </Button>
                        </div>
                      </div>
                    </fieldset>
                  </FormGroup> */}
                  {/* <FormGroup>
										<Label className="d-block track-input">
											<Input
												type="checkbox"
												name="checkTrack"
												checked={payload.checkTrack}
												value={payload.checkTrack}
												onChange={(e) =>
													handleOnChange(e.target, payload.checkTrack)
												}
											/>
											Track Quantity
										</Label>
									</FormGroup> */}
                  {payload.checkTrack && (
                    <FormGroup>
                      <Label className="col-form-label">Enable Product</Label>
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="d-block">
                          <Input
                            {...register("enableProduct")}
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="enableProduct"
                            checked={
                              payload.enableProduct === true ? true : false
                            }
                            // defaultValue={payload.quickSell || ''}
                            onChange={(e) => handleOnChange(e.target, true)}
                          />
                          <p className="text-danger">
                            {errors.enableProduct?.message}
                          </p>
                          Enable
                        </Label>
                        <Label className="d-block">
                          <Input
                            {...register("enableProduct")}
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="enableProduct"
                            checked={
                              payload.enableProduct === false ? true : false
                            }
                            // defaultValue={payload.quickSell || ''}
                            onChange={(e) => handleOnChange(e.target, false)}
                          />
                          <p className="text-danger">
                            {errors.enableProduct?.message}
                          </p>
                          Disable
                        </Label>
                      </div>
                    </FormGroup>
                  )}

                  {/* <FormGroup>
                    <Label className="d-block">
                      Available
                      <Input
                        type={!payload.checkTrack ? "text" : "number"}
                        name="trackQuantity"
                        value={
                          !payload.checkTrack
                            ? "Not tracked"
                            : payload.trackQuantity
                        }
                        disabled={!payload.checkTrack}
                        onChange={(e) => handleOnChange(e.target, "check")}
                      />
                    </Label>
                  </FormGroup> */}
                  <FormGroup>
                    <Label className="col-form-label">Quick Sell</Label>
                    <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                      <Label className="d-block">
                        <Input
                          {...register("quickSell")}
                          className="radio_animated"
                          id="edo-ani"
                          type="radio"
                          name="quickSell"
                          checked={payload.quickSell === true ? true : false}
                          // defaultValue={payload.quickSell || ''}
                          onChange={(e) => handleOnChange(e.target, true)}
                        />
                        <p className="text-danger">
                          {errors.quickSell?.message}
                        </p>
                        Active
                      </Label>
                      <Label className="d-block">
                        <Input
                          {...register("quickSell")}
                          className="radio_animated"
                          id="edo-ani1"
                          type="radio"
                          name="quickSell"
                          checked={payload.quickSell === false ? true : false}
                          // defaultValue={payload.quickSell || ''}
                          onChange={(e) => handleOnChange(e.target, false)}
                        />
                        <p className="text-danger">
                          {errors.quickSell?.message}
                        </p>
                        Deactive
                      </Label>
                    </div>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label className="col-form-label">
                      <span></span> Quick Sell Precentage :
                    </Label>

                    <Input
                      className="touchspin form-control"
                      type="text"
                      // disabled
                      name="quickSellPercentage"
                      value={payload.quickSellPercentage || ""}
                      onChange={(e) => handleOnChange(e.target)}
                    />

                    <p className="text-danger">
                      {errors.quickSellPercentage?.message}
                    </p>
                  </FormGroup> */}

                  {/* <FormGroup>
                    <Label className="col-form-label">Hide In-Store</Label>
                    <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                      {console.log('payload.visibility=>', payload.visibility)}
                      <Label className="d-block">
                        <Input
                          {...register("visibility")}
                          className="radio_animated"
                          id="edo-ani"
                          type="radio"
                          name="visibility"
                          checked={payload?.visibility === true ? true : false}
                          // defaultValue={payload.visibility || ''}
                          onChange={(e) => handleOnChange(e.target, true)}
                        />
                        <p className="text-danger">
                          {errors.visibility?.message}
                        </p>
                        Show
                      </Label>
                      <Label className="d-block">
                        <Input
                          {...register("visibility")}
                          className="radio_animated"
                          id="edo-ani1"
                          type="radio"
                          name="visibility"
                          checked={payload.visibility === false ? true : false}
                          // defaultValue={payload.visibility || ''}
                          onChange={(e) => handleOnChange(e.target, false)}
                        />
                        <p className="text-danger">
                          {errors.visibility?.message}
                        </p>
                        Hide
                      </Label>
                    </div>
                  </FormGroup>  */}

                  {/* <FormGroup>
                    <Label className="col-form-label">Select Size</Label>
                    <Input
                      name="size"
                      type="text"
                      value={payload?.size}
                      onChange={(e) => handleOnChange(e.target)}
                    />
                    <p className="text-danger">{errors.size?.message}</p>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <Label className="col-form-label">Select Color</Label>
                    <Input
                      name="color"
                      type="text"
                      value={payload?.color}
                      onChange={(e) => handleOnChange(e.target)}
                    />
                    <p className="text-danger">{errors.size?.message}</p>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <Label className="col-form-label">Capacity</Label>
                    <Select
                      isMulti
                      required
                      defaultValue={payload.setCapacity || []}
                      onChange={(opt) => handleSelectChange(opt, "capacity")}
                      options={sizeArray}
                      isClearable
                      name="size"
                    />
                    <p className="text-danger">{errors.size?.message}</p>
                  </FormGroup> */}
                  <FormGroup>
                    <Label className="col-form-label">
                      <span>*</span>
                      Category
                    </Label>
                    <Select
                      isMulti
                      defaultValue={payload.setCategory || []}
                      onChange={(opt) => handleSelectChange(opt, "categoryId")}
                      options={selectedCategory}
                      isClearable
                      name="categoryId"
                    />

                    <p className="text-danger">{customErrors?.categoryId}</p>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label className="col-form-label">
                      <span>*</span> Brand
                    </Label>
                    <Select
                      isMulti={false}
                      defaultValue={payload.setBrand || []}
                      onChange={(opt) => handleSelectChange(opt, "brandId")}
                      options={selectebrandList}
                      isClearable
                      name="brandId"
                    />
                    <p className="text-danger">{customErrors?.brandId}</p>
                  </FormGroup> */}
                  {/* <FormGroup>
                    <Label className="col-form-label">
                      <span></span> Partner
                    </Label>
                    <Select
                      isMulti={false}
                      defaultValue={payload.setPartner || []}
                      // onChange={handleChangePro}
                      onChange={(opt) => handleSelectChange(opt, "partnerId")}
                      options={selectePartnerList}
                      isClearable
                      name="partnerId"
                      //defaultValue={payload.partnerId}
                    />
                    <p className="text-danger">{customErrors?.partnerId}</p>
                  </FormGroup> */}
                  <FormGroup>
                    <Label className="col-form-label">
                      <span>*</span> Rarity Level
                    </Label>

                    <Select
                      isMulti={false}
                      onChange={(opt) => handleSelectChange(opt, "rarityLevel")}
                      //defaultValue={payload.rarityLevelId}
                      defaultValue={payload.setRarity || []}
                      // onChange={handleChangePro}
                      options={selectedRarity}
                      isClearable
                      name="rarityLevel"
                      required
                    />
                    <p className="text-danger">{customErrors?.rarityLevel}</p>
                  </FormGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="6">
            <Card>
              <CardBody>
                <div className="digital-add needs-validation">
                  {/* <FormGroup>
                    <Label className="col-form-label">
                      <span>*</span> Product Type
                    </Label>
                    <Select
                      isMulti={false}
                      // defaultValue={}
                      onChange={(opt) =>
                        handleSelectChange(opt, "productNature")
                      }
                      options={productNatureArray}
                      isClearable
                      name="productNature"
                      defaultValue={payload.setProductType || []}
                    />
                    <p className="text-danger">{customErrors?.productNature}</p>
                  </FormGroup> */}
                  <FormGroup className=" mb-0">
                    <div className="description-sm">
                      <Label className="col-form-label">Add Description</Label>
                      <textarea
                        rows="6"
                        cols="12"
                        {...register("description")}
                        className="form-control"
                        type="text"
                        defaultValue={payload.description || ""}
                      />
                      {errors.description && (
                        <p className="text-danger">
                          {errors.description.message}
                        </p>
                      )}
                      {/* <CKEditors
                        activeclassName="p10"
                        content={ddd}
                        events={{
                          change: handleChange,
                        }}
                      /> */}
                    </div>
                  </FormGroup>
                  <p>{ddd}</p>
                  {/* <FormGroup>
                    <Label className="col-form-label">Product Tag</Label>
                    <input
                      {...register("productTag")}
                      className="form-control"
                      type="text"
                      defaultValue={payload.productTag || ""}
                    />
                    <p className="text-danger">{errors.productTag?.message}</p>
                  </FormGroup> */}
                  <FormGroup>
                    <Label className="col-form-label pt-0">
                      Box Banner Image
                    </Label>
                    <div className="dropzone">
                      <div className="upload-img-preview">
                        {payload.bannerImage ? (
                          <div className="img-preview">
                            <img
                              src={
                                payload.bannerImage ||
                                URL.createObjectURL(previewBannerImage)
                              }
                              alt="NoImageFound"
                            />
                          </div>
                        ) : (
                          <img
                            className="img-preview"
                            src={prodImg}
                            alt="NoImageFound"
                          />
                        )}
                      </div>
                      <div className="file-upload">
                        <div className="file-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            {editName || "No file chosen..."}
                          </div>
                          <input
                            id="chooseFile"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={uploadBannerFile}
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label className="col-form-label pt-0">
                      {" "}
                      Other's Image
                    </Label>
                    <div className="dropzone">
                      <div className="upload-img-preview">
                        {(payload?.image || previewImage).length > 0 ? (
                          (payload?.image || previewImage).map((el, i) => (
                            <div className="img-preview">
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={(e) => removePhotos(e, i)}
                              ></i>{" "}
                              <img
                                src={
                                  payload?.image.length > 0
                                    ? el
                                    : URL.createObjectURL(el)
                                }
                                alt="NoImageFound"
                              />{" "}
                            </div>
                          ))
                        ) : (
                          <img
                            className="img-preview"
                            src={prodImg}
                            alt="NoImageFound"
                          />
                        )}
                      </div>
                      <div className="file-upload">
                        {checkStatus && (
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                        <div className="file-select">
                          <div className="file-select-button" id="fileName">
                            Choose File
                          </div>
                          <div className="file-select-name" id="noFile">
                            No file chosen...
                          </div>
                          <input
                            id="chooseFile"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            multiple
                            onChange={uploadFile}
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup> */}
                  {role?.some((r) =>
                    ["suUsers", "superAdmin", "writeProduct"].includes(r)
                  ) && (
                    <FormGroup>
                      <div className="product-buttons text-center mt-4">
                        <Button type="submit" color="primary" className="main-btn">
                          SUBMIT
                        </Button>
                      </div>
                    </FormGroup>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ProductForm;
