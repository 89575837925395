/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Edit, Trash2 } from "react-feather";
import { Button, CardBody, Col, Container, Row } from "reactstrap";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { useHistory, useLocation } from "react-router-dom";
import defaultproductimg from "../../assets/images/product-list/1.jpg";
import { queryString } from "./../../utils/queryString";
import { useSelector, useDispatch } from "react-redux";
import FilterProductFields from "../FilterProductComp";
import {
  getAllProductList,
  resetProducts,
  getAllRustItems,
  getComparePriceData,
  updateSearchData,
} from "../../reducers/adminReducers";
import ReactPaginate from "react-paginate";
import Loader from "../loader/loader";
const List_product = () => {
  const { id, userid } = queryString();
  const newId = id ? id : "";
  const serchInitialObj = {
    title: "",
    min: "",
    max: "",
    category: "",
    rarity: "",
    skip: 0,
    limit: 12,
    partnerId: newId,
    gameId: "730",
  };

  const history = useHistory();
  const location = useLocation();
  const { updated } = location.state || {};
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const {
    selectedCategory,
    productList,
    selectedRarity,
    totalProductsCount,
    loginAdmin,
    searchInitialData,
  } = result || {};
  const { role } = loginAdmin || [];
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  const [dummy, setDummy] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchPayload, setSeachPayload] = useState(
    updated === true ? searchInitialData : serchInitialObj
  );
  // const [skip,setSkip] = useState(0);
  // const [hide, setHide] = useState(false);
  const pageCount = Math.ceil(totalProductsCount / serchInitialObj.limit);

  useEffect(() => {
    if (updated === true) {
      const pageIndex = Math.floor(searchPayload.skip / searchPayload.limit);
      setSeachPayload((prev) => ({
        ...prev,
        skip: pageIndex * searchPayload.limit,
      }));
    }
    dispatch(getAllProductList(searchPayload));
    history.replace(location.pathname, {
      ...location.state,
      updated: false,
    });
  }, [dummy, updated]);
  const handleOnDelete = async (id) => {
    try {
      setDummy(false);
      setOpen(false);
      const response = await adminInstance().delete(
        `api/admin/deleteProduct/${id}`
      );
      const { code } = response.data;
      if (code === 200) {
        setDummy(true);
        toast.success("Successfully Deleted");
      } else {
        setDummy(false);
      }
    } catch (error) {
      console.log("----------->>>", error);
    }
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    setActiveId(id);
    setOpen(true);
  };

  const handleEdit = (id) => {
    setActiveId(id);
    history.push(`/products/create-product?id=${id}`);
  };

  const handleCompareProductPrice = (prodId) => {
    dispatch(updateSearchData(searchPayload));
    dispatch(getComparePriceData({ productId: prodId })).then((response) => {
      if (response?.payload?.resultData?.data?.code === 200) {
        history.push(`/products/compare-price?id=${prodId}`);
      }
    });
  };

  const handleSearchProduct = async (e) => {
    dispatch(resetProducts());
    const { value, name } = e.target;
    const copySearchPayLoad = { ...searchPayload };
    copySearchPayLoad[name] = value;
    copySearchPayLoad.skip = 0;
    setSeachPayload(copySearchPayLoad);
    dispatch(getAllProductList(copySearchPayLoad));
  };

  const addToInventory = async (pid) => {
    const payload = {
      productId: pid,
      userId: userid,
    };
    const response = await adminInstance().post(
      "api/admin/addToInventory",
      payload
    );
    const { code, msg } = response.data;
    if (code === 200) {
      toast.success(msg);
      history.push(`/user-profile-details?id=${userid}`);
    }
  };

  const handlePageClick = ({ selected }) => {
    const copySearchPayLoad = { ...searchPayload };
    copySearchPayLoad.skip = selected * searchPayload.limit;
    setSeachPayload(copySearchPayLoad);
    dispatch(getAllProductList(copySearchPayLoad));
  };

  const handleGetRustItems = () => {
    setLoader(true);
    const copySearchPayLoad = { ...searchPayload };
    copySearchPayLoad.skip = 0;
    dispatch(getAllRustItems(copySearchPayLoad)).then((response) => {
      if (response?.payload?.resultData?.data?.code === 200) {
        setLoader(false);
      } else {
        toast.error(response?.payload?.msg);
        setLoader(false);
      }
    });
  };

  const handleGameSelect = (item) => {
    setLoader(true);
    const copySearchPayLoad = { ...searchPayload };
    copySearchPayLoad.gameId = item.target.value;
    setSeachPayload(copySearchPayLoad);
    dispatch(getAllProductList(copySearchPayLoad)).then((respone) => {
      if (respone?.payload?.resultData?.data?.code === 200) {
        setLoader(false);
      }
    });
  };

  return (
    <Fragment>
      <Breadcrumb title="Product List" parent="Physical" />
      <Container fluid={true}>
        <FilterProductFields
          rarityList={selectedRarity}
          handleSearchProduct={handleSearchProduct}
          categoryList={selectedCategory}
          handleGetRustItems={handleGetRustItems}
          handleGameSelect={handleGameSelect}
        />
        {loader ? (
          <Loader />
        ) : (
          <Row className="products-admin ratio_asos product-list-page">
            {productList &&
              productList.map((myData, i) => {
                const { name, color } = myData.rarityLevel || {};
                return (
                  <Col xl="3" sm="6" key={i}>
                    <div className="products-admin">
                      <CardBody className="product-box">
                        <div
                          className="img-wrapper"
                          style={{
                            background: `linear-gradient(180deg, ${color}bf 50%, ${color} 100%)`,
                          }}>
                          <div className="lable-block">
                            {myData.productTag ? (
                              <span className="lable3">
                                {myData.productTag}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="front product-image">
                            <a
                              href="/products/list_product"
                              className="bg-size">
                              <img
                                alt=""
                                className="img-fluid blur-up bg-img lazyloaded product-img"
                                src={
                                  myData.bannerImage
                                    ? myData.bannerImage
                                    : defaultproductimg
                                }
                              />
                            </a>
                            <div className="product-hover">
                              <ul>
                                {role?.some((r) =>
                                  [
                                    "suUsers",
                                    "superAdmin",
                                    "writeProduct",
                                  ].includes(r)
                                ) && (
                                  <li>
                                    <Button
                                      color="btn"
                                      type="button"
                                      onClick={() => handleEdit(myData._id)}>
                                      <Edit className="editBtn" />
                                    </Button>
                                  </li>
                                )}
                                {role?.some((r) =>
                                  [
                                    "suUsers",
                                    "superAdmin",
                                    "delProduct",
                                  ].includes(r)
                                ) && (
                                  <li>
                                    <Button
                                      color="btn"
                                      type="button"
                                      onClick={() => handleDelete(myData._id)}>
                                      <Trash2 className="deleteBtn" />
                                    </Button>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="product-detail">
                          <div className="pro_contents">
                            <div className="pro_name">
                              <h6>{myData.name}</h6>
                            </div>
                            <div className="price_legen">
                              <p>{name}</p>
                              <h4>
                                $ {myData.price?.toFixed(2)}
                                <del>{myData.discount_price}</del>
                              </h4>
                              <div className="compare-price">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleCompareProductPrice(myData._id)
                                  }
                                  className="btn btn-primary">
                                  Compare Price
                                </button>
                              </div>
                            </div>
                          </div>
                          <ul className="color-variant">
                            {myData.color &&
                              myData.color.map((el) => {
                                return (
                                  <li
                                    className="bg-light0"
                                    style={{
                                      backgroundColor: el.toLowerCase(),
                                    }}></li>
                                );
                              })}
                          </ul>

                          {userid &&
                            role?.some((r) =>
                              [
                                "suUsers",
                                "superAdmin",
                                "writeProduct",
                              ].includes(r)
                            ) && (
                              <Button
                                type="button"
                                className="primary"
                                onClick={() => addToInventory(myData._id)}>
                                Add to Inventory
                              </Button>
                            )}
                        </div>
                      </CardBody>
                    </div>
                  </Col>
                );
              })}
          </Row>
        )}
        <Row>
          <Col className="d-flex justify-content-center pb-3">
            <div class="text-center search-pagination">
              {/* <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
              /> */}
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                forcePage={Math.floor(searchPayload.skip / searchPayload.limit)}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <DeleteConfirmPopup
        title={"Delete Product"}
        open={open}
        handleOnDelete={handleOnDelete}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};

export default List_product;
