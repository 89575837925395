import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import placeholder from "../../assets/images/fashion/product/12.jpg";
import { useHistory } from "react-router-dom";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { adminInstance } from "../../config/axios";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const Datatable = ({ id, myData, myClass, multiSelectOption, pagination }) => {
  // const Datatable = ({ id, myData, myClass, multiSelectOption, pagination }) => {
  const [open, setOpen] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [data, setData] = useState(myData);
  const history = useHistory();
  const [activeId, setActiveId] = useState();

  const [delData, setDelData] = useState({});
  console.log("myData", myData, delData);
  const selectRow = (e, i) => {
    if (!e.target.checked) {
      setCheckedValues(checkedValues.filter((item, j) => i !== item));
    } else {
      checkedValues.push(i);
      setCheckedValues(checkedValues);
    }
  };
  const handleRemoveRow = (id) => {
    try {
      const updatedData = myData.filter(function (el) {
        return checkedValues.indexOf(el.id) < 0;
      });
      setData([...updatedData]);
      setDelData({
        id,
      });
      toast.success("Successfully Deleted !");
    } catch (error) {
      console.log(error);
    }
  };

  // const renderEditable = (cellInfo) => {
  //   return (
  //     <>
  //       {console.log('fioefoefoefe', data)}
  //       <div
  //         style={{ backgroundColor: '#fafafa' }}
  //         contentEditable
  //         suppressContentEditableWarning
  //         onBlur={(e) => {
  //           data[cellInfo.index][cellInfo.index.id] = e.target.innerHTML
  //           setData({ myData: data })
  //         }}
  //         dangerouslySetInnerHTML={{
  //           __html: myData[cellInfo.index][cellInfo.index.id],
  //         }}
  //       />
  //     </>
  //   )
  // }

  // const handleDelete = (index) => {
  //   if (window.confirm('Are you sure you wish to delete this item?')) {
  //     const del = data
  //     del.splice(index, 1)
  //     setData([...del])
  //   }
  //   toast.success('Successfully Deleted !')
  // }
  // const onOpenModal = () => {
  //   setOpen(true)
  // }

  // const onCloseModal = () => {
  //   setOpen(false)
  // }

  const handleOnDelete = (id) => {
    console.log("idddd=>", id);
    setOpen(false);
  };
  const onCloseModal = async (id) => {
    const response = await adminInstance().post("api/admin/removeUser", id);
    const { code, msg } = response.data;
    console.log(code, msg);
    setOpen(false);
  };

  // const Capitalize = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1)
  // }

  const columns = [
    {
      name: "Image",
      cell: (row) => <ProfileImage row={row} />,
    },
    {
      name: "First Name",
      selector: (row) => row.firstname,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastname,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionButton
          row={row}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      ),
    },
  ];

  const handleEdit = (id) => {
    setActiveId(id);
    history.push(`/vendors/create-vendors?id=${id}`);
  };
  const handleDelete = (id) => {
    setActiveId(id);
    setOpen(true);
    console.log(id, "delete");
  };

  // const columns = []
  // for (var key in myData[0]) {
  //   // console.log('keysss==>>>',key)
  //   let editable = renderEditable
  //   if (key === 'image') {
  //     editable = null
  //   }
  //   if (key === 'status') {
  //     editable = null
  //   }
  //   if (key === 'avtar') {
  //     editable = null
  //   }
  //   if (key === 'vendor') {
  //     editable = null
  //   }
  //   if (key === 'order_status') {
  //     editable = null
  //   }

  //   columns.push({
  //     name: <b>{Capitalize(key.toString())}</b>,
  //     header: <b>{Capitalize(key.toString())}</b>,
  //     selector: key,
  //     Cell: editable,
  //     style: {
  //       textAlign: 'center',
  //     },
  //   })
  // }

  if (multiSelectOption === true) {
    columns.push({
      name: (
        <button
          className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
          onClick={(el) => {
            if (window.confirm("Are you sure you wish to delete this item?"))
              handleRemoveRow(el._id);
          }}>
          Delete
        </button>
      ),
      id: "delete",
      accessor: (str) => "delete",
      cell: (row) => (
        <div>
          {/* {console.log('rowwwws', row)} */}
          <span>
            <input
              type="checkbox"
              name={row.id}
              defaultChecked={checkedValues.includes(row.id)}
              onChange={(e) => selectRow(e, row.id)}
            />
          </span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: false,
    });
  } else {
    columns.push({
      name: <b>Action</b>,
      id: "delete",
      accessor: (str) => "delete",
      cell: (row, index) => (
        <div>
          {/* <span onClick={() => handleDelete(index)}>
            <i
              className='fa fa-trash'
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: '#e4566e',
              }}></i>
          </span> */}

          <span>
            {/* <i
              onClick={onOpenModal}
              className='fa fa-pencil'
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: 'rgb(40, 167, 69)',
              }}></i> */}
            <Modal
              isOpen={open}
              toggle={onCloseModal}
              style={{ overlay: { opacity: 0.1 } }}>
              <ModalHeader toggle={onCloseModal}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                  Edit Product
                </h5>
              </ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label htmlFor="recipient-name" className="col-form-label">
                      Category Name :
                    </Label>
                    <Input type="text" className="form-control" />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="message-text" className="col-form-label">
                      Category Image :
                    </Label>
                    <Input
                      className="form-control"
                      id="validationCustom02"
                      type="file"
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => onCloseModal("VaryingMdo")}>
                  Update
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => onCloseModal("VaryingMdo")}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </span>
        </div>
      ),
      style: {
        textAlign: "center",
      },
      sortable: false,
    });
  }
  return (
    <div>
      <Fragment>
        {console.log("uysudysuyduisyd", data)}
        <DataTable
          data={data}
          columns={columns}
          className={myClass}
          pagination={pagination}
          striped={true}
          center={true}
        />
        <DeleteConfirmPopup
          title={"Delete User"}
          open={open}
          handleOnDelete={handleOnDelete}
          onCloseModal={onCloseModal}
          id={activeId}
        />
      </Fragment>
    </div>
  );
};

const ProfileImage = ({ row }) => {
  return (
    <div className="profile-image">
      <img src={row.logo ? row.logo : placeholder} alt="logo" />
    </div>
  );
};

const ActionButton = ({ row, handleDelete, handleEdit }) => {
  return (
    <div className="action-btn">
      <span>
        <i
          onClick={() => handleDelete(row._id)}
          className="fa fa-trash"
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: "#e4566e",
          }}></i>
      </span>
      <span>
        <i
          onClick={() => handleEdit(row._id)}
          className="fa fa-pencil"
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: "rgb(40, 167, 69)",
          }}></i>
      </span>
    </div>
  );
};

export default Datatable;
