import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { adminInstance } from "../../config/axios";
import ListDatatable from "./listdatatable";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "../loader/loader";
import { handleBoxPrice } from "../../utils/multiuseFunctions";
const BoxList = () => {
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  const [BoxLists, setBoxLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  const [dummy, setDummy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPriceRange, setSelectedPriceRange] = useState("all");
  const [filteredItems, setFilteredItems] = useState([]);

  const getAllBoxessData = async () => {
    try {
      setLoading(true);
      const response = await adminInstance().get("api/admin/getBox");
      const { code, box } = response.data;
      if (code === 200) {
        handleBoxPrice(box);
        setLoading(false);
        setBoxLists(box);
        setFilteredItems(box);
      }
    } catch (error) {}
  };


  useEffect(() => {
    getAllBoxessData();
  }, [dummy]);
  const handleOnDelete = async (id) => {
    // setLoder(true);
    setDummy(false);
    try {
      setOpen(false);
      const response = await adminInstance().delete(
        `api/admin/deleteBox/${id}`
      );
      setDummy(true);
      const { code } = response.data;
      if (code === 200) {
        toast.success("Successfully Deleted");
        // setLoder(false);
      } else {
        setDummy(false);
      }
    } catch (error) {}
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  const handlePriceRangeChange = (e) => {
    if (BoxLists.length > 0) {
      const filteredByPrice = BoxLists.filter((box) => {
        if (e.target.value === "all") return true;
        const boxPrice = parseFloat(box?.boxPrice);
        if (e.target.value.startsWith(">")) {
          const minValue = parseFloat(e.target.value.slice(1));
          return boxPrice > minValue;
        } else if (e.target.value.startsWith("<")) {
          const maxValue = parseFloat(e.target.value.slice(1));
          return boxPrice < maxValue;
        } else {
          const [minValue, maxValue] = e.target.value
            .split(" - ")
            .map((value) => parseFloat(value));
          return boxPrice >= minValue && boxPrice <= maxValue;
        }
      });
      filteredByPrice.sort((a, b) => a.boxPrice - b.boxPrice);
      setFilteredItems(filteredByPrice);
    }
    setSelectedPriceRange(e.target.value);
  };

  return (
    <Fragment>
      <Breadcrumb title='Cases List' parent='Box' />
      <Container fluid={true}>
        <div className='admin-card'>
          {/* {isLoder && <Loader /> } */}
          <Card>
            <CardHeader>
              <h5>Cases Details </h5>
            </CardHeader>
            <CardBody>
              {role?.some((r) =>
                ["suUsers", "superAdmin", "writeBox"].includes(r)
              ) && (
                <div className='btn-popup pull-right'>
                  <Link to='/create-box' className='btn btn-secondary main-btn'>
                    Create Case
                  </Link>
                </div>
              )}

              <div className='clearfix'></div>
              
              <div className='sorting-dropdown'>
                <select
                  class='form-select custom-dropdown'
                  aria-label='Default select example'
                  value={selectedPriceRange}
                  onChange={handlePriceRangeChange}>
                  <option selected value='all'>
                    All
                  </option>
                  <option value='<5.00'>{"<"} $5.00</option>
                  <option value='5.00 - 25.00'>$5.00 - $25.00</option>
                  <option value='25.00 - 50.00'>$25.00 - $50.00</option>
                  <option value='50.00 - 100.00'>$50.00 - $100.00</option>
                  <option value='100.00 - 250.00'>$100.00 - $250.00</option>
                  <option value='250.00 - 500.00'>$250.00 - $500.00</option>
                  <option value='>500.00'>{">"} $500.00</option>
                </select>
              </div>

              <div
                id='batchDelete'
                className='category-table user-list order-table coupon-list-delete'>
                {!loading ? (
                  filteredItems?.length > 0 && (
                    <ListDatatable
                      multiSelectOption={true}
                      myData={filteredItems}
                      pageSize={10}
                      pagination={true}
                      class='-striped -highlight'
                      setActiveId={setActiveId}
                      setOpen={setOpen}
                      setBoxLists={setBoxLists}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
      <DeleteConfirmPopup
        title={"Delete Category"}
        open={open}
        handleOnDelete={handleOnDelete}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};
export default BoxList;
