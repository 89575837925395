import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import { adminInstance } from "../../config/axios";

import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { addAffiliateLevel, resetTeamData } from '../../reducers/adminReducers';
import Breadcrumb from '../common/breadcrumb';
//import { adminInstance } from '../../config/axios';
import { AddAffiliateLevelValidationSchema } from '../../utils/validation';
import Select from 'react-select';
import Loader from '../loader/loader';
const AddAffiliateLevel = () => {
	const initialState = {
		levelName: '',
		levelDescription: '',
		referrerCommissionType: '',
		referrerCommission: '',
		refereeCommissionType: '',
		refereeBonus: '',
		bonusRule: '',
		commissionRule: '',
		referrerCommissionTypeArray: [],
		refereeCommissionTypeArray: [],
		bonusRuleArray: [],
		comissionRuleArray: [],
		defaultLevel: false,
		defaultLevelForAll:true,
		xDepositBonusCount:'',
		xDepositCommissionCount:''
	};
	const commissionTypeOptions = [
		{ value: 'percentage', label: 'Percentage' },
		{ value: 'fixedAmount', label: 'Fixed Amount' },
	];

	const commissionRuleOptions = [
		{ value: 'firstDeposit', label: 'Pay commission on first deposit only' },
		{ value: 'allDeposit', label: 'Pay commission on all deposits' },
		{ value: 'xDeposit', label: 'Pay commission on X deposits' },
	]

	const bonusRuleOptions = [
		{ value: 'firstDeposit', label: 'Pay bonus on first deposit' },
		{ value: 'allDeposit', label: 'Pay bonus on all deposits' },
		{ value: 'xDeposit', label: 'Pay commission on X deposits' },
	]

	const history = useHistory();
	const dispatch = useDispatch();
	const [values, setValues] = useState(initialState);
	const [errors, setErrors] = useState({});
	// const [copyValues, setCopyValues] = useState(null)
	const [Loading, setLoading] = useState(true);
	// useEffect(() => {
	dispatch(resetTeamData());
	// }, [dispatch]);

	const onChangeInput = (e) => {


		const { name, value } = e.target;
		
		// if (!value || typeof value == 'number') {
		// 	setErrors("please enter valid input!");
		// } else {
		// 	setValues({
		// 		...values,
		// 		[name]: value,
		// 	});
		// }

		setValues({
			...values,
			[name]: value,
		});

	};

	// const handleOnblur = (e) => {
	// 	const { name, value } = e.target;
	// 	if (value === '') {
	// 		setErrors({ ...errors, [name]: `This field is required` });
	// 	} else if (name === 'firstName') {
	// 		setErrors({ ...errors, [name]: `Enter only character` });
	// 	} else if (name === 'lastName') {
	// 		setErrors({ ...errors, [name]: `Enter only character` });
	// 	} else if (name === 'email') {
	// 		setErrors({ ...errors, [name]: `Enter valid email` });
	// 	} else if (name === 'password') {
	// 		setErrors({
	// 			...errors,
	// 			[name]: `Must contain 8 characters, One uppercase, One lowercase, One number and one special case character`,
	// 		});
	// 	} else if (
	// 		name === 'confirmPassword' &&
	// 		values.password !== values.confirmPassword
	// 	) {
	// 		setErrors({ ...errors, [name]: `Password must be match` });
	// 	} else {
	// 		setErrors({ ...errors, [name]: '' });
	// 	}
	// };

	////////this is my change ///////////
	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery();
	let affiliateLevelId = query.get("id");

	const getSingleCategoryData = async (id) => {
		try {
			const response = await adminInstance().get(
				`api/admin/getAffiliateLevelById/${ id }`
			);
			const { code, affiliateLevel } = response.data;
			const copy = { ...values, ...affiliateLevel }

			const one = commissionTypeOptions.filter((el) => el.value === copy.refereeCommissionType);
			const three = commissionTypeOptions.filter((el) => el.value === copy.referrerCommissionType);
			const two = bonusRuleOptions.filter((el) => el.value === copy.bonusRule);
			const four = commissionRuleOptions.filter((el) => el.value === copy.commissionRule);
			copy['refereeCommissionTypeArray'] = one
			copy['bonusRuleArray'] = two
			copy['referrerCommissionTypeArray'] = three
			copy['comissionRuleArray'] = four;
			setValues(copy);

			if (code === 200) {
				setLoading(false);
				// setPayload(categoryData);
			}
		} catch (error) {
			console.log("----------->aaa>>", error);
		}

	};




	useEffect(() => {
		if (affiliateLevelId) {
			getSingleCategoryData(affiliateLevelId);
		} else {
			setLoading(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [affiliateLevelId]);


	const handleErrorValues = async (name) => {
		try {
			const validate = await AddAffiliateLevelValidationSchema.validate(values, {
				abortEarly: false,
			});
			if (validate) {
				return true;
			}
		} catch (error) {
			let er = {};
			error.inner.forEach((el) => {
				er[el.path] = el.message;
			});
			if (!name) {
				setErrors({ ...er });
			} else {
				setErrors({ ...errors, [name]: er[name] })
			}
			return false;
		}
	}

	const handleKeyUp = (e) => {
		const { name, value } = e.target;
		if (value !== '') {
			setErrors({ ...errors, [name]: '' });
		}
	};

	const handleOnblur = async (name) => {
		if (!stopBlur?.stop) {
			const isValid = await handleErrorValues(name);
			if (isValid) {
				setErrors({ ...errors, [name]: '' });
			}
			stopBlur.stop = false
		}

	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {

			if(values.bonusRule==="xDeposit" && !values.xDepositBonusCount)
			{
                   setErrors({...errors, xDepositBonusCount:"This Field is Required"})
				   return;
			}
			if(values.commissionRule==="xDeposit" && !values.xDepositCommissionCount)
			{
				setErrors({...errors, xDepositCommissionCount:"This Field is Required"})
				return;
			}
			
			const isValid = await handleErrorValues();
			if (isValid) {
				let res;
				if (affiliateLevelId) {
					res = await adminInstance().put(`api/admin/updateAffiliateLevel/${ affiliateLevelId }`, values);
				} else {
					let response = await dispatch(addAffiliateLevel(values));
					res = response?.payload?.response;
					setLoading(false);
				}
				if (res.status === 200) {
					history.push('/affiliate-level');
				}
				setErrors({})
			}
		} catch (error) {
			console.log('Error Is here In add roll -->', error);
		}
	};

	const stopBlur = {
		stop: false
	}

	const handleSelectChange = (opt, name) => {
		if (opt.value) {
			stopBlur.stop = true
			setErrors({ ...errors, [name]: '' });
			setValues({ ...values, [name]: opt.value });

		}

	}

	const handleOnChange = (e, value) => {
		setValues({
			...values, [e.name]: value
		})
	}



	return (

		<Fragment>

			<Breadcrumb title="Team Management" parent="Team" />

			{Loading ? <Loader /> : <Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className="addbox-form">
							<CardHeader>
								<h5> Add Affiliate Level </h5>
							</CardHeader>
							<CardBody>
								<div className="tab-pane fade show active">
									{/* <h5 className="f-w-600 f-16">Profile</h5> */}
									<Breadcrumb title="Affiliate" parent="Affiliate" />
									<div className="table-responsive profile-table setting-section sub-admin-section">
										<Form
											onSubmit={handleSubmit}
											className="needs-validation user-add"
										>
											<div className="admin-input col-xl-5 col-md-7">
												<FormGroup>
													<Label className="col-form-label pt-0">
														Level name
													</Label>
													<Input
														type="text"
														placeholder="Level Name"
														name="levelName"
														value={values?.levelName}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
													/>
													{errors && errors.levelName && (
														<span className="text-danger">
															{errors.levelName}
														</span>
													)}
												</FormGroup>
												<FormGroup>
													<Label className="col-form-label pt-0">
														Level description
													</Label>
													<textarea
														type="text"
														placeholder="Level Description"
														name="levelDescription"
														value={values?.levelDescription}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
														className="form-control"
													/>
													{errors && errors.levelDescription && (
														<span className="text-danger">
															{errors.levelDescription}
														</span>
													)}
												</FormGroup>
												<FormGroup>
													<Label className="col-form-label">
														<span>*</span>
														Referrer Commission type
													</Label>
													{/* <select name="cars" id="cars">
														<option value="volvo">Volvo</option>
														<option value="saab">Saab</option>
														<option value="mercedes">Mercedes</option>
														<option value="audi">Audi</option>
													</select> */}

													<Select
														// isMulti
														defaultValue={values.refereeCommissionTypeArray || []}
														onBlur={(e) => handleOnblur('referrerCommissionType')}
														onChange={(opt) => handleSelectChange(opt, 'referrerCommissionType')}
														options={commissionTypeOptions}
														// isClearable

														name="referrerCommissionType"
													/>

													<p className="text-danger">{errors?.referrerCommissionType}</p>
												</FormGroup>

												<FormGroup>
													<Label className="col-form-label pt-0">
														REFERER COMMISSION
													</Label>
													<Input
														type="number"
														min="0"
														placeholder="REFERER-COMMISSION"
														name="referrerCommission"
														value={values?.referrerCommission}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
													/>
													{errors && errors.referrerCommission && (
														<span className="text-danger">
															{errors.referrerCommission}
														</span>
													)}
												</FormGroup>

												<FormGroup>
													<Label className="col-form-label">
														<span>*</span>
														Referee commission type
													</Label>
													<Select
														// isMulti
														defaultValue={values.refereeCommissionTypeArray}
														onBlur={(e) => handleOnblur('refereeCommissionType')}
														onChange={(opt) => handleSelectChange(opt, 'refereeCommissionType')}

														options={commissionTypeOptions}
														// isClearable
														name="refereeCommissionType"
													/>

													<p className="text-danger">{errors?.refereeCommissionType}</p>
												</FormGroup>

												<FormGroup>
													<Label className="col-form-label pt-0">
														  REFEREE BONUS
													</Label>
													<Input
														type="number"
														min="0"
														placeholder="REFEREE-BONUS"
														name="refereeBonus"
														value={values?.refereeBonus}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
													/>
													{errors && errors.refereeBonus && (
														<span className="text-danger">
															{errors.refereeBonus}
														</span>
													)}
												</FormGroup>

												<FormGroup>
													<Label className="col-form-label">
														<span>*</span>
														BONUS RULE
													</Label>
													<Select
														// isMulti
														defaultValue={values.bonusRuleArray || []}
														onBlur={(e) => handleOnblur('bonusRule')}
														onChange={(opt) => handleSelectChange(opt, 'bonusRule')}

														options={bonusRuleOptions}
														// isClearable
														name="bonusRule"
													/>
													<p className="text-danger">{errors?.bonusRule}</p>
													{
														values.bonusRule==="xDeposit"&&
														<Input
														min="0"
														type="number"
														placeholder="fill the value of x"
														name="xDepositBonusCount"
														value={values?.xDepositBonusCount}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
													/>
													}
													<p className="text-danger">{errors?.xDepositBonusCount}</p>
												</FormGroup>
												<FormGroup>
													<Label className="col-form-label">
														<span>*</span>
														COMMISSION RULE
													</Label>
													<Select
														// isMulti
														defaultValue={values.comissionRuleArray || []}
														onBlur={(e) => handleOnblur('commissionRule')}
														onChange={(opt) => handleSelectChange(opt, 'commissionRule')}
														options={commissionRuleOptions}
														// isClearable
														name="commissionRule"
													/>
													<p className="text-danger">{errors?.commissionRule}</p>
													{
														values.commissionRule==="xDeposit"&&
														<Input
														type="number"
														min="0"
														placeholder="fill the value of x"
														name="xDepositCommissionCount"
														value={values?.xDepositCommissionCount}
														onChange={onChangeInput}
														onBlur={(e) => handleOnblur(e.target.name)}
														onKeyUp={handleKeyUp}
													/>
													}
													<p className="text-danger">{errors?.xDepositCommissionCount}</p>
												</FormGroup>
												<FormGroup>
													<Label className="col-form-label">DEFAULT LEVEL</Label>
													<div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
														{/* {console.log('payload.visibility=>', payload.visibility)} */}
														<Label className="d-block">
														<p>ON</p>
															<Input
																// {...register('visibility')}
																className="radio_animated"
																id="edo-ani"
																type="radio"
																name="defaultLevel"
																checked={values?.defaultLevel === true ? true : false}
																defaultValue={values.defaultLevel || ''}
																onChange={(e) => handleOnChange(e.target, true)}
															/>
															<p className="text-danger">
																{errors.defaultLevel?.message}
															</p>
															On
														</Label>
														<Label className="d-block">
															<p>OFF</p>
															<Input
																// {...register('defaultLevel')}
																className="radio_animated"
																id="edo-ani1"
																type="radio"
																name="defaultLevel"
																checked={values.defaultLevel === false ? true : false}
																defaultValue={values.defaultLevel || ''}
																onChange={(e) => handleOnChange(e.target, false)}
															/>
															<p className="text-danger">
																{errors.defaultLevel?.message}
															</p>
															Off
														</Label>
													</div>


{    values.defaultLevel&&
                                                    <div>
                                                           <h3>Chose Default</h3>
														   <Label className="d-block">
                                                                <p>For all users</p>
																<Input
																// {...register('visibility')}
																className="radio_animated"
																id="edo-ani"
																type="radio"
																name="defaultLevelForAll"
																checked={values?.defaultLevelForAll}
																// defaultValue={values?.defaultLevelForAll}
																onChange={(e) => handleOnChange(e.target, true)}
															/>
													        </Label>  
															<Label className="d-block">
                                                                <p>New  Users</p>
																<Input
																// {...register('visibility')}
																className="radio_animated"
																id="edo-ani"
																type="radio"
																name="defaultLevelForAll"
																checked={!values?.defaultLevelForAll}
																// defaultValue={!values?.defaultLevelForAll}
																onChange={(e) => handleOnChange(e.target, false)}
															/>
													        </Label>  

													</div>
}
												</FormGroup>
											</div>


											<div className="form-button">
												<Button
													color="primary"
													style={{ borderRadius: '25px' }}
													type="submit"
													className="main-btn"
												>
													Submit
												</Button>
											</div>
										</Form>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>


			</Container>}
		</Fragment>
	);
};
export default AddAffiliateLevel;
