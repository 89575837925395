import React from 'react';
import { Button, Label, Form, FormGroup } from 'reactstrap';
import { changeasswordValidation } from '../../utils/authValidation';
import { adminInstance } from '../../config/axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ChangePassword = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(changeasswordValidation()),
	});
	const onSubmitHandler = async (data) => {
		console.log('data >>>>>>', data);
		console.log('I ma here');
		try {
			if (Object.keys(errors).length === 0) {
				const response = await adminInstance().post(
					'/api/admin/changePassword',
					data
				);
				const { code, msg } = response.data;
				if (code === 200) {
					toast.success(msg);
					reset();
				} else {
					toast.error(msg);
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className="account-setting">
			<h5 className="f-w-600 f-16">Change Password</h5>
			<Form
				className="needs-validation user-add"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="col-form-label pt-0">
							<span>*</span> Current Password
						</Label>
						<input
							{...register('old_password')}
							className="form-control"
							placeholder="Current Password"
							type="text"
						/>
						<p className="text-danger">{errors.old_password?.message}</p>
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="col-form-label pt-0">
							<span>*</span> New Password
						</Label>
						<input
							{...register('password')}
							className="form-control"
							placeholder="New Password"
							type="text"
						/>
						<p className="text-danger">{errors.password?.message}</p>
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="col-form-label pt-0">
							<span>*</span> Confirm Password
						</Label>
						<input
							{...register('confirm_password')}
							className="form-control"
							placeholder="Confirm Password"
							type="text"
						/>
						<p className="text-danger">{errors.confirm_password?.message}</p>
					</div>
				</FormGroup>
				{role?.some((r) =>
					['suUsers', 'superAdmin', 'writeChangePassword'].includes(r)
				) && (
					<div className="form-button d-flex justify-content-start pt-3">
						<Button type="submit" color="primary" className='main-btn'>
							Update
						</Button>
					</div>
				)}
			</Form>
		</div>
	);
};

export default ChangePassword;
