import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import ListDatatable from './listdatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getAllBotPlayList } from '../../reducers/adminReducers';
import Loader from '../loader/loader';
const BotPlayList = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { allBotPlayList, loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);

	const getAllBotPlayLists = async () => {
		setLoading(true)
		const respnse = await dispatch(getAllBotPlayList(''));
		// console.log('This is boat play ', respnse?.payload)
		if (respnse?.payload) {
			setLoading(false)
		}
	}
	useEffect(() => {
		getAllBotPlayLists()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dummy])
	// useEffect(() => {

	// 	setLoading(true)
	// 	dispatch(getAllBotPlayList(''));
	// 	setLoading(false)
	// }, [dispatch, dummy]);
	const handleOnDelete = async (id) => {
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteBotPlayGame/${id}`
			);
			setDummy(true);
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted');
			} else {
				setDummy(false);
			}
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Bot Play List" parent="Box" />
			<Container fluid={true}>
				<div className="admin-card">
					{/* {isLoder && <Loader /> } */}
					<Card>
						<CardHeader>
							<h5>Play History </h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeBotSimulator'].includes(r)
							) && (
									<div className="btn-popup pull-right">
										<Link to="/play-bot" className="btn btn-secondary main-btn">
											Play
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									allBotPlayList && allBotPlayList?.length > 0 ?
										<ListDatatable
											multiSelectOption={true}
											myData={allBotPlayList}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
										/> : ''
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default BotPlayList;
