import React, { Fragment, useState } from 'react'
import { Tabs, TabPanel } from 'react-tabs' // TabList,  Tab
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yupAnnouncementValidation } from '../../../utils/authValidation'
// import { yupCategoryValidation } from '../../../utils/authValidation'
import { adminInstance, } from '../../../config/axios'
import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import '../../../assets/styles/admin.css'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { addFaq } from '../../../reducers/adminReducers.js'
//import { editFaqData } from '../../../reducers/adminReducers.js'
const AddFaqForm = ({ payload, faqcategories, selectedOption }) => {
  console.log('paulaod====>>>', payload)
  const dispatch = useDispatch();

  const history = useHistory();
  // const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
    // reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: payload || {},
    // resolver: yupResolver(yupCategoryValidation()),
    resolver: yupResolver(yupAnnouncementValidation()),
  });
  const onSubmitHandler = async (data) => {
    try {
      if (payload?._id) {
        // dispatch(editFaqData(payload._id))
        const response = await adminInstance().put(
          `api/admin/editFaqData/${payload._id}`, data
        );
        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg, { toastId: 1 });
        } else {
          toast.error(msg, { toastId: 1 });
        }
      } else {
        dispatch(addFaq(data))
        // const response = await adminInstance().post("api/admin/addFaq", data)
        // console.log("Response of Name check======>", response);

        // const { code, msg } = response.data;
        // if (code == 200) {
        //   toast.success(msg);
        // } else {
        //   toast.error(msg);
        // }
      }
      history.push("/faq");


    } catch (error) {
      console.log("Error", error)
    }
  }
  const [selectedList, setSelectedList] = useState([])
  const [errormsg, seterrorMsg] = useState('')

  const handleChangePro = (Opt) => {
    console.log("Heloondbsd", Opt)
    const datas = Array.isArray(Opt) ? Opt.map((x) => x.value) : []
    setSelectedList(datas)
    console.log('datasnfhgdjdudidiudbgibdlagfil=>', datas)
    if (datas && datas.length > 0) {
      seterrorMsg('')
    }
    setValue('category[]', datas)
  }
  console.log('Check selected List', selectedList)
  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <h4>Add Faq</h4>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Title
                </Label>
                <input
                  {...register("title")}
                  className="form-control"
                  type="text"
                />
                <p className="text-danger">{errors.title?.message}</p>
                {/* <h5 className="text-danger">{success}</h5> */}
              </div>

            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Description </Label>
                <textarea
                  rows='4'
                  cols='12'
                  {...register('description')}
                  className='form-control'
                  type='text'
                  defaultValue={payload.description || ''}
                />
                {/* <p className="text-danger">{errors.description?.message}</p> */}
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Category
                </Label>
                <Select
                  isMulti
                  defaultValue={selectedOption}
                  onChange={handleChangePro}
                  options={faqcategories}
                  isClearable
                  name='category'
                />
                {errormsg ? (
                  <p className='text-danger'>{errormsg}</p>
                ) : (
                  <p className='text-danger'>{errors.category?.message}</p>
                )}
              </div>
            </FormGroup>
            <div className="form-button d-flex justify-content-start pt-3">
              <Button color="primary" type="submit" className='main-btn'>
                Submit
              </Button>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}
export default AddFaqForm