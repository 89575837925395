import moment from 'moment';

export const handleBoxPrice = (daata) => {
  daata.forEach((ele) => {
    let totalPriceOfItems = 0;
    const houseEdgePercentage = parseFloat(ele.profitMargin) / 100;
    ele.boxItems.forEach((el) => {
      const prodPrice = el?.pid?.price;
      if (prodPrice && el?.chance) {
        const res = prodPrice * (parseFloat(el?.chance) / 100);
        totalPriceOfItems += parseFloat(res);
      }
    });
    const houseEdge = 1 - houseEdgePercentage;
    const finalBoxPrice = totalPriceOfItems / houseEdge;
    ele['boxPrice'] = finalBoxPrice.toFixed(2);
  });
};

export const generatePassword = () => {
  var length = 12;
  var string = 'abcdefghijklmnopqrstuvwxyz';
  var numeric = '0123456789';
  // eslint-disable-next-line no-useless-escape
  var punctuation = '!@#$%^&*()_+~`|}{[]:?></-=';
  var password = '';
  var character = '';
  while (password.length < length) {
    var entity1 = Math.ceil(string.length * Math.random() * Math.random());
    var entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    var entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    var hold = string.charAt(entity1);
    // eslint-disable-next-line eqeqeq
    hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split('')
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join('');
  return password.substr(0, length);
};
export const getBoxPrice = (daata, profitMargin) => {
  const houseEdgePercentage = parseFloat(profitMargin) / 100;
  let totalPriceOfItems = 0;
  daata?.forEach((el) => {
    const prodPrice = el?.pid?.price;
    if (prodPrice && el?.chance) {
      const res = prodPrice * (parseFloat(el?.chance) / 100);
      const finalRes = res.toFixed(2);
      totalPriceOfItems += parseFloat(finalRes);
    }
  });
  const houseEdge = 1 - houseEdgePercentage;
  const finalBoxPrice = totalPriceOfItems / houseEdge;
  return finalBoxPrice?.toFixed(2);
};

export const getClientSeed = () => {
  const rand = () => Math.random(0).toString(36).substr(2);
  const token = (length) =>
    (rand() + rand() + rand() + rand()).substr(0, length);
  return token(32);
};

export const boxPriceCalculation = (daata, profitMargin) => {
  const houseEdgePercentage = parseFloat(profitMargin) / 100;
  let totalBoxPrice = 0;
  let totalPriceOfItems = 0;
  daata?.forEach((el) => {
    const prodPrice = el?.productPrice;
    if (prodPrice && el?.chance) {
      const res = prodPrice * (parseFloat(el?.chance) / 100);
      const finalRes = res.toFixed(2);
      totalPriceOfItems += parseFloat(finalRes);
    }
  });
  const houseEdge = 1 - houseEdgePercentage;
  totalBoxPrice = totalPriceOfItems / houseEdge;
  let totalOdds = daata.reduce(
    (pre, next) =>
      parseFloat(pre || 0) + parseFloat(next.chance ? next.chance : 0),
    0
  );
  let remainingOdds = 100 - parseFloat(totalOdds);
  return {
    boxPrice: totalBoxPrice?.toFixed(2),
    boxOdds: totalOdds.toFixed(4),
    remainingOdds: remainingOdds.toFixed(4),
  };
};

export const singleboxPriceCalculation = (itemprice, itemchance) => {
  const chanceofItem = parseFloat(itemchance) / 100;
  const totalPriceOfItems = (chanceofItem || 0) * itemprice;
  // let totalPriceOfItems = 0;
  // const { productPrice, chance } = data || {};

  // if (productPrice && chance) {
  //   const res = parseFloat(productPrice) * (parseFloat(chance) / 100);
  //   totalPriceOfItems += parseFloat(res);
  // }
  // const houseEdge = 1 - houseEdgePercentage;
  // const singleBoxPrice = (totalPriceOfItems / houseEdge)?.toFixed(2);
  return totalPriceOfItems?.toFixed(2);
};

export const getItmesRange = (itemArray) => {
  let initial = 0;
  let newArrayRange = [];
  itemArray.forEach((el) => {
    let startRange = initial + 1;
    let lastRange = initial + el.chance * 10000;
    let newOBj = el;
    newOBj.range = startRange + '-' + lastRange;
    //console.log('newOBj=>', newOBj)
    newArrayRange.push(newOBj);
    initial = lastRange;
  });
  return newArrayRange;
};
const calcTime = (dd) => {
  const offset = '+1';
  // create Date object for current location
  const d = new Date(dd);
  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * offset);

  const dates = moment(nd).format('YYYY-MM-DD');
  // return time as a string
  // return  nd.toLocaleString();
  return dates;
};

export const filterDateObj = (dataObj) => {
  const { type, rangeStartDate, rangeEndDate } = dataObj || {};
  // console.log('dataObj===>', dataObj)
  const dates = moment(new Date()).subtract(1, 'd').format('YYYY-MM-DD');
  const curr = new Date();
  if (type === 'yesterday') {
    const lt = moment(new Date(dates)).add(1, 'd').format('YYYY-MM-DD');
    const gt = new Date(dates);
    const obj = {
      startDate: new Date(calcTime(lt)).toISOString(),
      endDate: new Date(calcTime(gt)).toISOString(),
      summaryDate: `${moment(gt).format('ddd, D MMM yyyy')} - ${moment(
        gt
      ).format('ddd, D MMM yyyy')}`,
      type,
    };
    return obj;
  }
  if (type === 'last7day') {
    const lt = moment(new Date(dates)).format('YYYY-MM-DD');
    const gt = moment().subtract(7, 'd').format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(lt)).toISOString(),
      endDate: new Date(calcTime(gt)).toISOString(),
      summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(
        gt
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'last28day') {
    const lt = moment(new Date(dates)).format('YYYY-MM-DD');
    const gt = moment().subtract(28, 'd').format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(lt)).toISOString(),
      endDate: new Date(calcTime(gt)).toISOString(),
      summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(
        gt
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'last90day') {
    const lt = moment(new Date(dates)).format('YYYY-MM-DD');
    const gt = moment().subtract(90, 'd').format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(lt)).toISOString(),
      endDate: new Date(calcTime(gt)).toISOString(),
      summaryDate: `${moment(calcTime(lt)).format(
        'ddd, D MMM yyyy'
      )} - ${moment(gt).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'today') {
    const lt = moment(new Date(dates)).add(1, 'd').format('YYYY-MM-DD');
    const gt = moment().add(1, 'd').format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(gt)).toISOString(),
      endDate: new Date(calcTime(lt)).toISOString(),
      summaryDate: `${moment(new Date()).format('ddd, D MMM yyyy')} - ${moment(
        new Date()
      ).format('ddd, D MMM yyyy')}`,
      type,
    };
    return obj;
  }
  if (type === 'tomorrow') {
    const lt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    //const gt = new Date(dates).setDate(new Date(dates).getDate() + 2);
    const obj = {
      startDate: new Date(calcTime(lt)).toISOString(),
      endDate: new Date(calcTime(lt)).toISOString(),
      summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(
        lt
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'week') {
    // const first = curr.getDate() - curr.getDay();
    // const last = first + 8;
    // const firstday = moment(new Date(curr.setDate(first + 1))).format('YYYY-MM-DD');
    // const lastday = moment(curr.setDate(last)).format('YYYY-MM-DD');
    const filterStart = new Date(
      moment().startOf('isoWeek').format('YYYY-MM-DD')
    );
    const filterEnd = new Date(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    const obj = {
      startDate: new Date(
        calcTime(moment().endOf('isoWeek').format('YYYY-MM-DD'))
      ).toISOString(),
      endDate: new Date(
        calcTime(moment().startOf('isoWeek').format('YYYY-MM-DD'))
      ).toISOString(),
      summaryDate: `${moment(filterEnd).format('ddd, D MMM yyyy')} - ${moment(
        filterStart
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'month') {
    const firstDay = moment(
      new Date(curr.getFullYear(), curr.getMonth(), 1)
    ).format('YYYY-MM-DD');
    const lastDay = moment(
      new Date(curr.getFullYear(), curr.getMonth() + 1, 0)
    ).format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(lastDay)).toISOString(),
      endDate: new Date(calcTime(firstDay)).toISOString(),
      summaryDate: `${moment(lastDay).format('ddd, D MMM yyyy')} - ${moment(
        firstDay
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'year') {
    const obj = {
      startDate: new Date(
        calcTime(moment().endOf('year').format('YYYY-MM-DD'))
      ).toISOString(),
      endDate: new Date(
        calcTime(moment().startOf('year').format('YYYY-MM-DD'))
      ).toISOString(),
      summaryDate: `${moment()
        .endOf('year')
        .format('ddd, D MMM yyyy')} - ${moment()
        .startOf('year')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'lastweek') {
    const obj = {
      startDate: new Date(
        moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('ddd, D MMM yyyy')} - ${moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'lastyear') {
    const obj = {
      startDate: new Date(
        moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, 'year').startOf('year').format('YYYY-DD-MM')
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('ddd, D MMM yyyy')} - ${moment()
        .subtract(1, 'year')
        .endOf('month')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'nextweek') {
    const obj = {
      startDate: new Date(
        moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
      ).toISOString(),
      endDate: new Date(
        moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, 'weeks')
        .startOf('isoWeek')
        .format('ddd, D MMM yyyy')} - ${moment()
        .add(1, 'weeks')
        .endOf('isoWeek')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'nextyear') {
    const obj = {
      startDate: new Date(
        moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')
      ).toISOString(),
      endDate: new Date(
        moment().add(1, 'year').startOf('year').format('YYYY-MM-DD')
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, 'year')
        .startOf('year')
        .format('ddd, D MMM yyyy')} - ${moment()
        .add(1, 'year')
        .endOf('year')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'weektodate') {
    const obj = {
      startDate: new Date(
        calcTime(moment(new Date()).format('YYYY-MM-DD'))
      ).toISOString(),
      endDate: new Date(
        calcTime(moment().startOf('isoWeek').format('YYYY-MM-DD'))
      ).toISOString(),
      summaryDate: `${moment(new Date()).format('ddd, D MMM yyyy')} - ${moment()
        .startOf('isoWeek')
        .format('ddd, D MMM yyyy')} `,
    };
    return obj;
  }
  if (type === 'monthtodate') {
    const obj = {
      startDate: new Date(
        calcTime(moment(new Date()).format('YYYY-MM-DD'))
      ).toISOString(),
      endDate: new Date(
        calcTime(moment().clone().startOf('month').format('YYYY-MM-DD'))
      ).toISOString(),
      summaryDate: `${moment(new Date()).format('ddd, D MMM yyyy')} - ${moment()
        .clone()
        .startOf('month')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'yeartodate') {
    const obj = {
      startDate: new Date(
        calcTime(moment(new Date()).format('YYYY-MM-DD'))
      ).toISOString(),
      endDate: new Date(
        calcTime(moment().startOf('year').format('YYYY-MM-DD'))
      ).toISOString(),
      summaryDate: `${moment(new Date()).format('ddd, D MMM yyyy')} - ${moment()
        .startOf('year')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (type === 'daterange' || type === 'custom') {
    const endDate = moment(new Date(rangeEndDate)).format('YYYY-MM-DD');
    const startDate = moment(new Date(rangeStartDate)).format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(calcTime(endDate)).toISOString(),
      endDate: new Date(calcTime(startDate)).toISOString(),
      summaryDate: `${moment(endDate).format('ddd, D MMM yyyy')} - ${moment(
        startDate
      ).format('ddd, D MMM yyyy')}`,
    };

    //console.log({rangeEndDate,rangeStartDate })
    //   const obj = {
    // 	startDate: new Date(calcTime(moment(rangeEndDate).format('YYYY-MM-DD'))).toISOString(),
    // 	endDate: new Date(calcTime(moment(rangeStartDate))).format('YYYY-MM-DD')).toISOString(),
    // 	summaryDate: `${moment(rangeEndDate).format('ddd, D MMM yyyy')} - ${moment(rangeStartDate).format('ddd, D MMM yyyy')}`,
    //   };
    //console.log('obj=======>', obj)
    return obj;
  }
  return {};
};

export const validateImage = (img) => {
  const size = img.size;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      let picFile = event.target;
      let imgNew = new Image();
      imgNew.src = window.URL.createObjectURL(img);
      imgNew.addEventListener('load', () => {
        // console.log("kkkk", imgNew.height,
        // imgNew.width, size);
        let valid = true;
        let msg = '';
        if (size < 1000000) {
          if (imgNew.width <= 1000 && imgNew.height <= 800) {
            msg = 'ok';
            valid = true;
            resolve(reader.result);
          } else {
            msg = 'Image dimension must be less than or equal to (1000 x 800)';
            valid = false;
            reject({ msg, valid });
          }
        } else {
          msg = 'Image size must be less than 1 MB';
          valid = false;
          reject({ msg, valid });
        }
      });
      imgNew.addEventListener('error', () => {
        reject();
      });
      imgNew.src = picFile.result;
    });
    reader.readAsDataURL(img);
  });
};

// export const filterDateObj = (dataObj) => {
// 	const {
// 	  type,
// 	  rangeStartDate,
// 	  rangeEndDate,
// 	} = dataObj || {};
// 	const dates = moment(new Date()).format('YYYY-MM-DD');
// 	const curr = new Date();
// 	if (type === 'yesterday') {
// 	  const lt = new Date(dates);
// 	  const gt = new Date(dates).setDate(new Date(dates).getDate() - 1);
// 	  const obj = {
// 		startDate: new Date(lt).toISOString(),
// 		endDate: new Date(gt).toISOString(),
// 		summaryDate: `${moment(gt).format('ddd, D MMM yyyy')} - ${moment(gt).format('ddd, D MMM yyyy')}`,
// 		type,
// 	  };
// 	  return obj;
// 	}if (type === 'last7day') {
// 		const lt = moment(new Date(dates)).format('YYYY-MM-DD');
// 		const gt = moment().subtract(7,'d').format('YYYY-MM-DD');
// 		const obj = {
// 		  startDate: new Date(lt).toISOString(),
// 		  endDate: new Date(gt).toISOString(),
// 		  summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(gt).format('ddd, D MMM yyyy')}`,
// 		};
// 		return obj;
// 	}
// 	if (type === 'last28day') {
// 		const lt = moment(new Date(dates)).format('YYYY-MM-DD');
// 		const gt = moment().subtract(28,'d').format('YYYY-MM-DD');
// 		const obj = {
// 		  startDate: new Date(lt).toISOString(),
// 		  endDate: new Date(gt).toISOString(),
// 		  summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(gt).format('ddd, D MMM yyyy')}`,
// 		};
// 		return obj;
// 	}
// 	if (type === 'last90day') {
// 		const lt = moment(new Date(dates)).format('YYYY-MM-DD');
// 		const gt = moment().subtract(90,'d').format('YYYY-MM-DD');
// 		const obj = {
// 		  startDate: new Date(lt).toISOString(),
// 		  endDate: new Date(gt).toISOString(),
// 		  summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(gt).format('ddd, D MMM yyyy')}`,
// 		};
// 		return obj;
// 	}
// 	 if (type === 'today') {
// 	  const lt = new Date(dates);
// 	  const gt = moment().add(1,'d').format('YYYY-MM-DD');
// 	  const obj = {
// 		startDate: new Date(gt).toISOString(),
// 		endDate: new Date(lt).toISOString(),
// 		summaryDate: `${moment(new Date()).format('ddd, D MMM yyyy')} - ${moment(new Date()).format('ddd, D MMM yyyy')}`,
// 		type
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'tomorrow') {
// 	  const lt = new Date(dates).setDate(new Date(dates).getDate() + 1);
// 	  //const gt = new Date(dates).setDate(new Date(dates).getDate() + 2);
// 	  const obj = {
// 		startDate: new Date(lt).toISOString(),
// 		endDate: new Date(lt).toISOString(),
// 		summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(lt).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	} if (type === 'week') {
// 	  // const first = curr.getDate() - curr.getDay();
// 	  // const last = first + 8;
// 	  // const firstday = moment(new Date(curr.setDate(first + 1))).format('YYYY-MM-DD');
// 	  // const lastday = moment(curr.setDate(last)).format('YYYY-MM-DD');
// 	  const filterStart = new Date(moment().startOf('isoWeek').format('YYYY-MM-DD'));
// 	  const filterEnd = new Date(moment().endOf('isoWeek').format('YYYY-MM-DD'));
// 	  const obj = {
// 		startDate: new Date(moment().endOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().startOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment(filterStart).format('ddd, D MMM yyyy')} - ${moment(filterEnd).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	} if (type === 'month') {
// 	  const firstDay = moment(new Date(curr.getFullYear(), curr.getMonth(), 1)).format('YYYY-MM-DD');
// 	  const lastDay = moment(new Date(curr.getFullYear(), curr.getMonth() + 1, 0)).format('YYYY-MM-DD');
// 	  const obj = {
// 		startDate: new Date(lastDay).toISOString(),
// 		endDate: new Date(firstDay).toISOString(),
// 		summaryDate: `${moment(firstDay).format('ddd, D MMM yyyy')} - ${moment(lastDay).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'year') {
// 	  const obj = {
// 		startDate: new Date(moment().endOf('year').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().startOf('year').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().startOf('year').format('ddd, D MMM yyyy')} - ${moment().endOf('year').format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'lastweek') {
// 	  const obj = {
// 		startDate: new Date(moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().subtract(1, 'weeks').startOf('isoWeek').format('ddd, D MMM yyyy')} - ${moment().subtract(1, 'weeks').endOf('isoWeek').format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'lastyear') {
// 	  const obj = {
// 		startDate: new Date(moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().subtract(1, 'year').startOf('year').format('YYYY-DD-MM')).toISOString(),
// 		summaryDate: `${moment().subtract(1, 'year').startOf('year').format('ddd, D MMM yyyy')} - ${moment().subtract(1, 'year').endOf('month').format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'nextweek') {
// 	  const obj = {
// 		startDate: new Date(moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().add(1, 'weeks').startOf('isoWeek').format('ddd, D MMM yyyy')} - ${moment().add(1, 'weeks').endOf('isoWeek').format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'nextyear') {
// 	  const obj = {
// 		startDate: new Date(moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().add(1, 'year').startOf('year').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().add(1, 'year').startOf('year').format('ddd, D MMM yyyy')} - ${moment().add(1, 'year').endOf('year').format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'weektodate') {
// 	  const obj = {
// 		startDate: new Date(moment(new Date()).format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().startOf('week').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().startOf('week').format('ddd, D MMM yyyy')} - ${moment(new Date()).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'monthtodate') {
// 	  const obj = {
// 		startDate: new Date(moment(new Date()).format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().clone().startOf('month').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().clone().startOf('month').format('ddd, D MMM yyyy')} - ${moment(new Date()).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'yeartodate') {
// 	  const obj = {
// 		startDate: new Date(moment(new Date()).format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment().startOf('year').format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment().startOf('year').format('ddd, D MMM yyyy')} - ${moment(new Date()).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	if (type === 'daterange' || type === 'custom') {
// 	  const obj = {
// 		startDate: new Date(moment(new Date(rangeEndDate)).format('YYYY-MM-DD')).toISOString(),
// 		endDate: new Date(moment(new Date(rangeStartDate)).format('YYYY-MM-DD')).toISOString(),
// 		summaryDate: `${moment(rangeStartDate).format('ddd, D MMM yyyy')} - ${moment(rangeEndDate).format('ddd, D MMM yyyy')}`,
// 	  };
// 	  return obj;
// 	}
// 	return {};
//   };

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Define an array for month names
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Extract the desired parts of the date
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Return the formatted date
  return `${month} ${day}, ${year}`;
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  // Define an array for month names
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Extract the desired parts of the date
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Extract time in HH:MM AM/PM format
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert 24-hour to 12-hour format

  // Return the formatted date and time
  return `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
};
