import React, { useState } from "react";
import TipPopup from "./tipPopup";
import JoinPopup from "./joinPopup";
import moment from "moment";
import { currencyFormat } from "../../utils/currencyFormater";
function Datatablelist({
  setShowTip,
  showTip,
  handleShowTip,
  setShowJoin,
  showJoin,
  handleShowJoin,
  allRaindropsList,
  joinUserData,
  tipUserData,
  setJoinUserData,
  setTipUserData,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page

  // Logic to calculate indexes for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = allRaindropsList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(allRaindropsList.length / itemsPerPage);
  const pageRangeStart = (currentPage - 1) * itemsPerPage + 1;
  const pageRangeEnd =
    currentPage === totalPages
      ? allRaindropsList.length
      : currentPage * itemsPerPage;

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages ? prevPage : prevPage + 1
    );
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const handleItemsPerPageChange = (e) => {
    setCurrentPage(1);
    setItemsPerPage(parseInt(e.target.value, 10));
  };
  return (
    <div>
      <div className='mt-4'>
        <table className='table table-striped'>
          <thead className='table-head'>
            <tr>
              <th scope='col'>S.No</th>
              <th scope='col'>Date Time</th>
              <th scope='col'>Tip Amount</th>
              <th scope='col'>Drop Status</th>
              <th scope='col'>Join User</th>
              <th scope='col'>Tip User</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {currentItems.map((raindrop, index) => (
              <tr className='country-table' key={index}>
                <td>{index + 1}</td>
                <td>
                  {moment(raindrop?.createdAt).format("DD/MM/YYYY HH:mm")}
                </td>
                <td>{currencyFormat(raindrop?.dropAmount)}</td>
                <td
                  className={raindrop.isFinished ? "completed" : "processing"}>
                  {raindrop.isFinished ? "Completed" : "Processing"}
                </td>
                <td>
                  <button
                    className='btn btn-primary profile-btn'
                    onClick={() => handleShowJoin(raindrop._id)}>
                    Show
                  </button>
                </td>
                <td>
                  <button
                    className='btn btn-primary profile-btn'
                    onClick={() => handleShowTip(raindrop._id)}>
                    Show
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <ul className='custom-pagination'>
          <div className='select-pages'>
            <span className='select-page-item'>Rows per page:</span>
            <select
              className='select-number'
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}>
              {allRaindropsList.length > 0 &&
                Array.from(
                  {
                    length:
                      allRaindropsList.length > 10
                        ? Math.min(allRaindropsList.length, 15)
                        : allRaindropsList.length + 5,
                  },
                  (_, index) => (
                    <option key={index} value={(index + 1) * 5}>
                      {(index + 1) * 5}
                    </option>
                  )
                )}
            </select>
          </div>
          <div className='pagination-info mr-3'>
            {pageRangeStart} - {pageRangeEnd} of {allRaindropsList.length}
          </div>
          <li className='page-item'>
            <button onClick={goToFirstPage} className='page-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                aria-hidden='true'
                fill='rgba(0, 0, 0, 0.54)'
                role='presentation'>
                <path d='M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z'></path>
                <path fill='none' d='M24 24H0V0h24v24z'></path>
              </svg>
            </button>
          </li>
          <li className='page-item'>
            <button onClick={goToPrevPage} className='page-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                aria-hidden='true'
                fill='rgba(0, 0, 0, 0.54)'
                role='presentation'>
                <path d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'></path>
                <path d='M0 0h24v24H0z' fill='none'></path>
              </svg>
            </button>
          </li>
          <li className='page-item'>
            <button onClick={goToNextPage} className='page-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='rgba(0, 0, 0, 0.54)'
                aria-hidden='true'
                role='presentation'>
                <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'></path>
                <path d='M0 0h24v24H0z' fill='none'></path>
              </svg>
            </button>
          </li>
          <li className='page-item'>
            <button onClick={goToLastPage} className='page-icon'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='rgba(0, 0, 0, 0.54)'
                aria-hidden='true'
                role='presentation'>
                <path d='M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z'></path>
                <path fill='none' d='M0 0h24v24H0V0z'></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
      <TipPopup
        showTip={showTip}
        setShowTip={setShowTip}
        tipUserData={tipUserData}
        setTipUserData={setTipUserData}
      />
      <JoinPopup
        setShowJoin={setShowJoin}
        showJoin={showJoin}
        joinUserData={joinUserData}
        setJoinUserData={setJoinUserData}
      />
    </div>
  );
}

export default Datatablelist;
