import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import ListDatatable from './listdatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
// import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBattles, getBattles } from '../../reducers/adminReducers';
import Loader from '../loader/loader';
const BattleList = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { allBattles, loginAdmin } = result;
	const { role } = loginAdmin || [];
	const [BoxLists, setBoxLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getAllBoxessData = async () => {
		try {
			setLoading(true)
			const response = await adminInstance().get('api/admin/getBox');
			const { code, box } = response.data;
			
			console.log('box==>123', BoxLists);
			if (code === 200) {

				setLoading(false)
				setBoxLists(box);
			}
		} catch (error) { }
	};

	useEffect(() => {
		getAllBoxessData();
		//setLoading(true);
		dispatch(getBattles());
		//setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dummy, dispatch]);
	const handleOnDelete = async (id) => {
		// setLoder(true);
		setDummy(false);
		try {
			setOpen(false);
			dispatch(deleteBattles(id));
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Battle List" parent="Battle" />
			<Container fluid={true}>
				<div className="admin-card">
					{/* {isLoder && <Loader /> } */}
					<Card>
						<CardHeader>
							<h5>Battle Details </h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeBattle'].includes(r)
							) && (
									<div className="btn-popup pull-right">
										<Link to="/create-battle" className="btn btn-secondary main-btn">
											Create Battle
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									allBattles && allBattles.length > 0 ?
										<ListDatatable
											multiSelectOption={true}
											myData={allBattles}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setBoxLists={setBoxLists}
										/>
										: <h1>Data does not found</h1>
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default BattleList;
