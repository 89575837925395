import React, { Fragment, useState, useEffect } from 'react';
import { Tabs, TabPanel } from 'react-tabs'; // TabList,  Tab
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { yupAnnouncementValidation } from '../../utils/authValidation';
// import { adminInstance } from '../../config/axios'
// import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom';
// import placeholder from '../images/placeholder.png';
import '../../assets/styles/admin.css';
import { useDispatch } from 'react-redux';
import {
	createAnnouncement,
	updateAnnouncement,
	resetAnnouncementData,
} from '../../reducers/adminReducers';

const AddAnnouncementForm = ({ payload, setPayload }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		// reset,
		// setValue,
	} = useForm({
		mode: 'onBlur',
		defaultValues: payload || {},
		resolver: yupResolver(yupAnnouncementValidation()),
	});

	const [selectedFile, setSelectedFile] = useState();
	//   const [newSelectedFile,setSelecte]
	const [preview, setPreview] = useState(payload.image || '');

	const onSubmitHandler = async (data) => {
		const formData = new FormData();
		formData.append('file', data?.image[0]);
		delete data.image;
		Object.keys(data).map((el) => formData.append(el, data[el]));
		try {
			const { _id } = payload || {};
			if (Object.keys(errors).length === 0) {
				// }
				if (_id) {
					dispatch(updateAnnouncement(formData));
				} else {
					dispatch(createAnnouncement(formData));
				}
				dispatch(resetAnnouncementData());
				history.push('/announcements');
			}
		} catch (error) {
			console.log('error', error);
		}
	};
	useEffect(() => {
		if (!selectedFile) {
			setPreview(payload.image || '');
			//   setSelectedFile(payload.image || '')
			//   return
		}
		if (selectedFile) {
			const objectUrl = URL.createObjectURL(selectedFile);
			setPreview(objectUrl);
			return () => URL.revokeObjectURL(objectUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(e.target.files[0]);
	};
	return (
		<Fragment>
			<Tabs>
				<TabPanel>
					<Form
						className="needs-validation user-add admin-type "
						onSubmit={handleSubmit(onSubmitHandler)}
					>
						{/* <h4>Add Category</h4> */}
						<FormGroup className="row justify-content-start mx-0">
							<div className="admin-input col-xl-5 col-md-7">
								<Label className="col-form-label pt-0">
									<span>*</span> Name
								</Label>
								<input
									{...register('title')}
									className="form-control"
									type="text"
									defaultValue={payload.title || ''}
								/>

								<p className="text-danger">{errors.title?.message}</p>
							</div>
						</FormGroup>
						<FormGroup className="row justify-content-start mx-0">
							<div className="admin-intype col-xl-5 col-md-7">
								<Label for="chooseFile" className="col-form-label pt-0">
									<span></span> Image
								</Label>
								<div className="file-upload">
									<div className="file-select">
										<div className="file-select-button" id="fileName">
											Choose File
										</div>
										<div className="file-select-name" id="noFile">
											No file chosen...
										</div>
										<input
											id="chooseFile"
											name="chooseFile"
											{...register('image')}
											type="file"
											accept="image/png, image/gif, image/jpeg"
											onChange={onSelectFile}
											//   defaultValue={payload.image || ''}
										/>
									</div>
									{(payload.image || selectedFile) && (
										<img style={{ width: '40%' }} src={preview} alt="as" />
									)}
								</div>
							</div>
						</FormGroup>

						{/* <FormGroup>
              {}
              <Label className='col-form-label'>Category Type</Label>
              <div className='m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated'>
                <Label className='d-block'>
                  <Label className='col-form-label'>Box</Label>
                  <input
                    {...register('categoryType')}
                    type='radio'
                    value='box'
                    // checked={payload.categoryType === 'box' ? true : false}
                  />
                  <p className='text-danger'>{errors.categoryType?.message}</p>
                  Active
                </Label>
                <Label className='d-block'>
                  <Label className='col-form-label'>Product</Label>
                  <input
                    {...register('categoryType')}
                    type='radio'
                    value='product'
                    defaultChecked
                    // checked={payload.categoryType === 'product' ? true : false}
                  />
                  <p className='text-danger'>{errors.categoryType?.message}</p>
                  Deactive
                </Label>
              </div>
            </FormGroup> */}

						<FormGroup className="row justify-content-start mx-0">
							<div className="admin-intype col-xl-5 col-md-7 ">
								<Label className="col-form-label pt-0">
									<span></span> Description{' '}
								</Label>
								<textarea
									rows="4"
									cols="12"
									{...register('description')}
									className="form-control"
									type="text"
									defaultValue={payload.description || ''}
								/>
							</div>
						</FormGroup>
						<div className="form-button d-flex justify-content-start pt-3">
							<Button color="primary" type="submit" className='main-btn'>
								Submit
							</Button>
						</div>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};
export default AddAnnouncementForm;
