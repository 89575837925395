import React, { Fragment, useEffect, useState } from 'react';
//import { Button } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container,Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Breadcrumb from '../common/breadcrumb';
import ShippingListDataTable from './shippingListDataTable';
import { getAllShippingData,createShipping } from '../../reducers/adminReducers';
import Loader from '../loader/loader';
import AddShippingType from './addShippingType';
const ShippingManagement = () => {
	const initialState={
		shippingName:''
	}
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { allShippingData,loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [deliveryMethod,setDeliveryMethod]=useState(initialState)
	const [openEditDelivery,setOpenEditDelivery]=useState(false)
	useEffect(() => {
		setLoading(true);
		dispatch(getAllShippingData());
		setLoading(false);
	}, [dispatch]);
	const handleAddDeliveryMethod = async () => {
		try {
	
			
				setOpen(false);
				const payload = {
					shippingName:deliveryMethod.shippingName,
				};
				const response = await dispatch(createShipping(payload));
				const { msg, code } = response?.payload;
				if (code === 200) {
					//toast.success(msg);
					dispatch(getAllShippingData());
					setDeliveryMethod('')
				} else {
					toast.error(msg);
				}
			
			
		} catch (error) {
			console.log('error', error);
		}
	};
	const handleOpenShipping=()=>{
		setOpen(true)
	}
	const onCloseModal = () => {
		setOpen(false);
		setDeliveryMethod('')
	};
	return (
		<>
			<Fragment>
			<AddShippingType
				open={open}
				handleUpdate={handleAddDeliveryMethod}
				onCloseModal={onCloseModal}
				deliveryMethod={deliveryMethod}
				setDeliveryMethod={setDeliveryMethod}
				title="Add Delivery Method"
			/>
				<Breadcrumb title="Delivery Management" parent="Delivery" />
				<Container fluid={true}>
					<div className="admin-card">
						<Card>
							<CardHeader>
								<h5>All Delivery Method </h5>
							</CardHeader>
							<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeDelivery','writePreferences'].includes(r)
							) && (
								<div className="btn-popup pull-right">
									<Button  className="btn btn-secondary main-btn" onClick={handleOpenShipping}>
										Create Delivery Method
									</Button>
								</div>
								)}
								<div className="clearfix"></div>
								<div
									id="batchDelete"
									className="category-table user-list order-table coupon-list-delete"
								>
									{!loading ? (
										allShippingData.length > 0 && (
											<ShippingListDataTable
												multiSelectOption={true}
												myData={allShippingData}
												pageSize={10}
												pagination={true}
												class="-striped -highlight"
												handleAddDeliveryMethod={handleAddDeliveryMethod}
												setOpenEditDelivery={setOpenEditDelivery}
												openEditDelivery={openEditDelivery}
												setDeliveryMethod={setDeliveryMethod}
                                                deliveryMethod={deliveryMethod}
											/>
										)
									) : (
										<Loader />
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</Container>
			</Fragment>
		</>
	);
};
export default ShippingManagement;
