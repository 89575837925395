// import React from 'react'
import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';

// import ListDatatable from './listDatatable';
import DeleteConfirmPopup from '../../common/deleteConfirmPopup';
import FaqListDataTable from './listDatatable';
import { adminInstance } from '../../../config/axios';
// import { Loader } from 'react-feather';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loader from '../../loader/loader';

const FaqManagement = () => {


	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [faqLists, setFaqLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getFaqCategorylist = async () => {
		try {
			setLoading(true);
			const response = await adminInstance().get('api/admin/getAllfaqCategoryData');
			const { code, allFaqData } = response.data;
			if (code === 200) {
				setLoading(false);
				setFaqLists(allFaqData);
			}
		} catch (error) { }
	};

	useEffect(() => {
		getFaqCategorylist();
	}, [dummy]);
	const handleOnDelete = async (id) => {
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteFaqCategory/${id}`
			);
			setDummy(true);
			// console.log("DELETE RESPONSE ++++>>>>>>", response.data)
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted', { toastId: 1 });
			} else {
				setDummy(false);
			}
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Faq Management" parent="Faq" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>Create Category</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeBrand'].includes(r)
							) && (
									<div className="btn-popup pull-right ">
										<Link to="/faq-addcategory" className="btn btn-secondary main-btn">
											Create
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									faqLists && faqLists.length > 0 ?
										<FaqListDataTable
											multiSelectOption={true}
											myData={faqLists}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setFaqLists={setFaqLists}
											handleOnDelete={handleOnDelete}
										/>
										: <h1>Data is not found</h1>
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	)
}

export default FaqManagement