import React, { useEffect, useState } from "react";
import { Button, Label, Form, FormGroup } from "reactstrap";
import { updateTopTenUsers, getTopTenUser } from "../../reducers/adminReducers";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
const DailyRaceForm = ({ role }) => {
  const initialData = {
    rankOne: 0,
    rankTwo: 0,
    rankThree: 0,
    rankFour: 0,
    rankFive: 0,
    rankSix: 0,
    rankSeven: 0,
    rankEight: 0,
    rankNine: 0,
    rankTen: 0,
  };
  const [rankData, setRankData] = useState(initialData);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { topTenUserData } = result || {};
  const handleSubmitPrice = (e) => {
    e.preventDefault();
    setLoader(true);
    dispatch(updateTopTenUsers(rankData))
      .then((res) => {
        if (res?.payload?.resultData?.data?.code === 200) {
          toast.success(res?.payload?.resultData?.data?.message);
          dispatch(getTopTenUser());
          setLoader(false);
        } else {
          toast.error(
            res?.payload?.resultData?.data?.message || "Some Thing went Wrong"
          );
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  useEffect(() => {
    dispatch(getTopTenUser());
  }, [dispatch]);

  useEffect(() => {
    if (topTenUserData) {
      setRankData(topTenUserData);
    }
  }, [topTenUserData]);

  return (
    <>
      {loader && <Loader />}
      <Form className="needs-validation user-add" onSubmit={handleSubmitPrice}>
        <div className="container-fluid">
          <div className="row">
            {[
              "One",
              "Two",
              "Three",
              "Four",
              "Five",
              "Six",
              "Seven",
              "Eight",
              "Nine",
              "Ten",
            ].map((elem, index) => (
              <div className="col-xl-6 col-md-4">
                <FormGroup className="row justify-content-start mx-0">
                  <Label className="col-form-label pt-0">
                    Rank {elem} ({index + 1})
                  </Label>
                  <input
                    className="form-control"
                    placeholder={`Rank ${elem} Price`}
                    type="number"
                    name={`rank${elem}`}
                    value={rankData[`rank${elem}`]}
                    onChange={(e) => {
                      setRankData({
                        ...rankData,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </div>
            ))}
          </div>
        </div>
        {role?.some((r) =>
          [
            "suUsers",
            "superAdmin",
            "writeQuickSellPercentage",
            "writePreferences",
          ].includes(r)
        ) && (
          <div className="form-button d-flex justify-content-start pt-3">
            <Button type="submit" color="primary" className="main-btn">
              {loader ? "Updating..." : "Update"}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default DailyRaceForm;
