import React, { useState } from 'react';
// import TabsetProfile from './tabset-profile'
// import Breadcrumb from '../common/breadcrumb'
// import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap'
// import defaultprofile from '../../assets/images/defaultprofile.jpg'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { adminInstance } from '../../config/axios';
import {
	Button,
	// Col,
	//Input,
	Label,
	Form,
	FormGroup,
} from 'reactstrap';
import { getAdminData } from '../../reducers/adminReducers';

const QuckSellForm = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin, adminSetting } = result || {};
	const { role } = loginAdmin || {};
	const { quickSellPercentage } = adminSetting || {};
	const [emptyError, setEmptyError] = useState();
	const [newquickSellPercentage, setNewquickSellPercentage] = useState(
		quickSellPercentage || '0'
	);
	const onSubmitHandleData = async (e) => {
		e.preventDefault();
		try {
			if (
				newquickSellPercentage !== '' &&
				parseFloat(newquickSellPercentage) >= 0

			) {

				setEmptyError('');
				const payload = {
					quickSellPercentage: newquickSellPercentage,
				};

				const response = await adminInstance().put(
					'/api/admin/UpdateQuickSellPercentage',
					payload
				);
				const { code, msg } = response.data;
				if (code === 200) {
					toast.success(msg);
					dispatch(getAdminData());
				} else {
					toast.error(msg);
				}
			} else {
				setEmptyError('This field is required');
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleOnchange = (value) => {
		setNewquickSellPercentage(value);
	};

	return (
		<Form className="needs-validation user-add" onSubmit={onSubmitHandleData}>
			<FormGroup className="row justify-content-start mx-0">
				<div className="admin-input col-xl-5 col-md-7">
					<Label className="col-form-label pt-0">
						<span>*</span> Percentage
					</Label>
					<input
						className="form-control"
						placeholder="quick Sell Percentage"
						type="number"
						step="0.01"
						defaultValue={newquickSellPercentage || quickSellPercentage}
						onChange={(e) => handleOnchange(e.target.value)}
					/>
					<p className="text-danger">{emptyError || ''}</p>
				</div>
			</FormGroup>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'writeQuickSellPercentage', 'writePreferences'].includes(r)
			) && (
					<div className="form-button d-flex justify-content-start pt-3">
						<Button type="submit" color="primary" className='main-btn'>
							Update
						</Button>
					</div>
				)}
		</Form>
	);
};

export default QuckSellForm;
