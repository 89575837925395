import React from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
// import deleteimg from '../../assets/images/delete-icon.png';
import "../../assets/styles/deletePopup.css";
const ConfirmationPopUp = ({
  title,
  onCloseModal,
  open,
  handleConfirm,
  id,
  displayName,
  handleReason,
  payload,
  orderDetails,
  selected,
  handleSelected,
  selectedlen,
}) => {
  return (
    <Modal
      className={`delete-modal-dialog ${
        displayName === "process" ? "order-process-popup" : ""
      }`}
      isOpen={open}
      toggle={onCloseModal}
      style={{ overlay: { opacity: 0.1 } }}
      centered
    >
      <ModalHeader
        onClick={onCloseModal}
        className="delete-modal-header"
        toggle={onCloseModal}
      ></ModalHeader>
      <ModalBody>
        {selectedlen !== 0 ? (
          <>
            <h3>
              {title}
              <br />
            </h3>
            <label>Message</label>

            {displayName === "process" && (
              <div className="selected-items-detail">
                <h4>Total Selected Items: {selectedlen}</h4>
                <div className="selected-items">
                  {orderDetails?.orderSummary?.length > 0 &&
                    orderDetails?.orderSummary?.map((el) => (
                      <div className="d-flex">
                        {el?.status === "shipped" ? (
                          <input
                            type="checkbox"
                            checked={selected?.includes(el?._id)}
                            onChange={() => handleSelected(el?._id)}
                            disabled
                            //checked disabled
                          />
                        ) : (
                          <input
                            type="checkbox"
                            checked={selected?.includes(el?._id)}
                            onChange={() => handleSelected(el?._id)}
                            // checked
                          />
                        )}
                        {/* <input
                      type='checkbox'
                      checked={selected?.includes(el?._id)}
                      onChange={() => handleSelected(el?._id)}
                      disabled={el.status === 'Shipped' ? 'checked disabled' : ''}
                    /> */}
                        <img src={el?.productId?.bannerImage} alt="" />
                        <p>{el?.productId?.name}</p>
                        <p>{el?.quantity}</p>
                        <p>{el?.price}</p>
                      </div>
                    ))}
                </div>
              </div>
            )}

            <div className="admin-input">
              {displayName === "process" && (
                <div className="desc">
                  <Label className="col-form-label pt-0">
                    No. of Items Shipped
                  </Label>
                  <input
                    onChange={handleReason}
                    className="form-control"
                    type="number"
                    name="shippedItems"
                    value={selectedlen}
                  />
                </div>
              )}
              {(displayName === "hold" ||
                displayName === "deny" ||
                displayName === "process") && (
                <div className="desc">
                  <Label className="col-form-label pt-0">Reason</Label>
                  <textarea
                    onChange={handleReason}
                    className="form-control"
                    type="text"
                    name="reason"
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <h6> Items is shipped</h6>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          className="delete-button"
          style={{ background: "#ff4d53 !important" }}
          onClick={() => handleConfirm(id)}
        >
          Confirm
        </Button>
        <Button
          type="button"
          color="secondary"
          className="cancel-button"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmationPopUp;
