/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
// import { Link } from 'react-router-dom'
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
// import { useSelector, useDispatch } from 'react-redux'
// import { adminInstance } from '../../config/axios'
// import { getAllRarityList } from '../../../reducers/adminReducers'
import ListDatatable from "./listdatatable";
import ConfirmationPopUp from "../common/ConfirmationPopUp";
// import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { withdrawPaymentTransactions } from "../../reducers/adminReducers";
import Loader from "../loader/loader";

const FundsManagement = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { withdrawPaymentTransactionsList } = result || {};

  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  //   const [paymentStatus,setPaymentStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    transactionId: "",
    approvedStatus: "",
    amount: 0,
    userId: "",
  });

  const getPaymentTransactionDetails = async () => {
    setLoading(true);
    const response = await dispatch(withdrawPaymentTransactions("withdrawal"));
    if (response?.payload) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentTransactionDetails();
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Breadcrumb title="Withdraw Funds List" parent="Withdraw" />
      <Container fluid={true}>
        <div className="admin-card">
          <Card>
            <CardHeader>
              <h5>Withdraw Details</h5>
            </CardHeader>
            <CardBody>
              <div className="btn-popup pull-right"></div>
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete filter__input"
              >
                {!loading ? (
                  withdrawPaymentTransactionsList?.data?.length > 0 && (
                    <ListDatatable
                      multiSelectOption={true}
                      myData={withdrawPaymentTransactionsList}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                      setActiveId={setActiveId}
                      setOpen={setOpen}
                      setPayload={setPayload}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>

      <ConfirmationPopUp
        title={`Are You Sure Want to ${
          payload?.approvedStatus === "declined" ? "Decline" : "Approve"
        } `}
        subtitle=""
        open={open}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};
export default FundsManagement;
