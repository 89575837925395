import React, { Fragment } from 'react'
import { Tabs, TabPanel } from 'react-tabs' // TabList,  Tab
import { Button, Form, FormGroup, Label, } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yupCategoryValidation } from '../../../utils/authValidation'
import { adminInstance, } from '../../../config/axios'
import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import '../../../assets/styles/admin.css'
import { useHistory } from 'react-router-dom'

const AddCategoryFaqForm = ({ payload }) => {
  // console.log('paulaod====>>>', payload)
  const history = useHistory();
  // const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: payload || {},
    resolver: yupResolver(yupCategoryValidation()),
  });

  // const [success, setSuccess] = useState();

  const onSubmitHandler = async (data) => {

    // console.log("DATA CHECK", data)
    try {
      if (payload?._id) {
        const response = await adminInstance().put(
          `api/admin/editFaqCategoryData/${payload._id}`, data
        );
        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg);
        } else {
          toast.error(msg);
        }
        // console.log("Response data===>", response.data.code)
      } else {
        const response = await adminInstance().post("api/admin/addFaqCategory", data)
        // console.log("Response of Name", response);

        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg);
        } else {
          toast.error(msg);
        }
      }
      history.push("/faq-category");


    } catch (error) {
      console.log("Error", error)
    }
  }

  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <h4>Add Category</h4>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Name
                </Label>
                <input
                  {...register("name")}
                  className="form-control"
                  type="text"
                />
                <p className="text-danger">{errors.name?.message}</p>
                {/* <h5 className="text-danger">{success}</h5> */}
              </div>

            </FormGroup>
            <div className="form-button d-flex justify-content-start pt-3">
              <Button color="primary" type="submit" className='main-btn'>
                Submit
              </Button>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}

export default AddCategoryFaqForm