import React, { Fragment, useState, useEffect } from "react";
import { Tabs, TabPanel } from "react-tabs";
import { Button, Form, FormGroup, Label, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupBoxValidation } from "../../utils/authValidation";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import defaultproductimg from "../../assets/images/product-list/1.jpg";
import ReactPaginate from "react-paginate";
import NumberFormat from "react-number-format";
import {
  singleboxPriceCalculation,
  getItmesRange,
} from "../../utils/multiuseFunctions";
import Select from "react-select";
import { validateImage } from "../../utils/multiuseFunctions";

//import DateTimePicker from 'react-datetime-picker';
const AddBoxForm = ({
  payload,
  products,
  productList,
  categoryList,
  handleSearchProduct,
  rarityList,
  handlePageClick,
  pageCount,
  handleProduct,
  handleOnchangePro,
  chooseProduct,
  handleRemove,
  bxPrice,
  boxCategoryList,
  totalOdds,
  selectebrandList,
  selectePartnerList,
  boxProfit,
  remainOdds,
  handleSelectChange,
  cerrors,
  setCerrors,
  handleOnChangeVisibility,
  handleFeaturedBox,
  handleOnChangeFreeBox,
  handleBoxBattle,
  handleProfitMorgin,
  boxLatestColor,
  handleLatestBoxColor,
  initialSpecialProductTags,
  handleBoxLevel,
  handlePriceCategoryChange,
  handleExpensiveItems,
  inexpensiveItems,
}) => {
  console.log("product lis", productList);
  const history = useHistory();
  let totalChance = 100;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: payload || {},
    resolver: yupResolver(yupBoxValidation()),
  });
  const [editData, setEditData] = useState();
  const [editName, setEditName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const onlyInexpensiveItems = chooseProduct
    .filter((item) => inexpensiveItems[item?.pid])
    .reduce((acc, curr) => acc + parseFloat(curr?.chance || 0), 0);

  const selectChance = payload?.priceCategory;
  const requiredPercentForInexpensive = totalChance - selectChance;
  const leftPercent = requiredPercentForInexpensive - onlyInexpensiveItems;

  const onSubmitHandler = async (data) => {
    try {
      const { _id } = payload || {};
      if (Object.keys(errors).length === 0) {
        if (payload.category.length > 0) {
          if (products && products.length > 0) {
            const everychance = chooseProduct.every(
              (el) => el.chance && parseFloat(el.chance) !== 0
            );
            if (payload?.priceCategory > 0 && leftPercent > 0) {
              return toast.error(
                `Inexpensive Items percent total must be ${requiredPercentForInexpensive}%`,
                { toastId: "inexpensiveItems" }
              );
            }
            const newchooseProduct = getItmesRange(chooseProduct);
            if (everychance) {
              if (parseFloat(totalOdds) === 100) {
                const formData = new FormData();
                formData.append("file", data?.logo[0]);
                formData.append(
                  "chooseProduct",
                  JSON.stringify(newchooseProduct)
                );
                formData.append("category", JSON.stringify(payload.category));
                formData.append("itemsCount", Number(chooseProduct?.length));
                formData.append("hideBox", Number(payload?.hideBox));
                formData.append("boxQuantity", Number(payload?.boxQuantity));
                formData.append("featuredcase", payload?.featuredcase);
                formData.append("boxTimer", payload?.boxTimer);
                formData.append("freeBox", payload?.freeBox);
                formData.append("enableBox", payload?.enableBox);
                formData.append(
                  "specialTag",
                  JSON.stringify(payload?.specialTag)
                );
                formData.append(
                  "caseSpecialTag",
                  JSON.stringify(payload?.caseSpecialTag)
                );
                formData.append("color", payload?.color);
                formData.append("boxBattle", payload?.boxBattle);
                formData.append("boxlevel", payload?.boxlevel);
                formData.append(
                  "priceCategory",
                  Number(payload?.priceCategory)
                );
                formData.append(
                  "boxProfit",
                  Number(payload?.boxProfit) || Number(boxProfit)
                );
                delete data.logo;
                Object.keys(data).map((el) => formData.append(el, data[el]));
                if (_id) {
                  const response = await adminInstance().put(
                    `/api/admin/updateBox/${_id}`,
                    formData
                  );
                  const { code, msg } = response.data;
                  setEditData({ ...editData, isUpdate: true });
                  if (code === 200) {
                    toast.success(msg);
                    reset();
                    history.push("/box-list");
                  } else {
                    toast.error(msg);
                  }
                } else {
                  const response = await adminInstance().post(
                    "/api/admin/createBox",
                    formData
                  );
                  const { code, msg } = response.data;
                  if (code === 200) {
                    toast.success(msg);
                    reset();
                    history.push("/box-list");
                  } else {
                    toast.error(msg);
                  }
                }
              } else {
                toast.error("Total chance limit must be 100%");
              }
            } else {
              toast.error("Please add chance for every product");
            }
          } else {
            toast.error("Please select product");
          }
        } else {
          setCerrors("This field is required");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = async (e) => {
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined);
        const noFileText = document.getElementById("noFile");
        noFileText.textContent = "No file chosen...";

        return;
      }
      await validateImage(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
      const noFileText = document.getElementById("noFile");
      noFileText.textContent = e.target.files[0].name;
    } catch (error) {
      const { msg, valid } = error || {};
      if (!valid) {
        toast.error(msg);
      }
    }
  };

  useEffect(() => {
    if (payload?._id) {
      reset(payload);
    }
  }, [payload, reset]);

  const specialOptions = [
    { value: "featured", label: "Featured Case" },
    { value: "newBoxs", label: "New Boxes" },
    { value: "topBoxs", label: "Top Boxes" },
    { value: "hotBoxes", label: "Hot Boxes" },
    { value: "techBoxs", label: "Tech Boxes" },
    { value: "gamingBoxes", label: "Gaming Boxes" },
  ];

  const priceCatOptions = [
    { value: 1, label: "1%" },
    { value: 5, label: "5%" },
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
    { value: 25, label: "25%" },
    { value: 50, label: "50%" },
  ];

  useEffect(() => {
    const imageName = payload?.image;
    let name = "";
    if (imageName) {
      name = imageName?.substring(imageName?.lastIndexOf("/") + 1);
      name = name.replace(/_\d+/g, "");
      name = name.slice(-15);
      setEditName(name);
    }
  }, [payload]);

  const itemsType = [
    { value: "statTrak", label: "StatTrak™" },
    { value: "nonstatTrak", label: "Non-StatTrak™" },
  ];

  return (
    <Fragment className="addbox-form">
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add"
            onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col xl="12">
                <Row>
                  <Col xl="6">
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span>*</span> Name
                          </Label>
                          <input
                            {...register("name")}
                            className="form-control"
                            type="text"
                            defaultValue={payload.name || ""}
                          />
                          <p className="text-danger err-message">
                            {errors.name?.message}
                          </p>
                        </div>
                      </div>
                    </FormGroup>

                    {/* <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span></span> Color
                          </Label>

                          {payload?.color && (
                            <input
                              className="form-control"
                              type="color"
                              name="color"
                              value={payload?.color || ""}
                              onChange={(e) =>
                                handleLatestBoxColor(e.target.value)
                              }
                            />
                          )}

                          <ul className="box-input-color">
                            {}
                            {boxLatestColor &&
                              boxLatestColor.length > 0 &&
                              boxLatestColor.map((el) => (
                                <li
                                  style={{ backgroundColor: `${el}` || "" }}
                                  onClick={() =>
                                    handleLatestBoxColor(el, "select")
                                  }></li>
                              ))}
                          </ul>
                          <p className="text-danger err-message">
                            {errors.color?.message}
                          </p>
                        </div>
                      </div>
                    </FormGroup> */}

                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span>*</span> Special tag
                          </Label>
                          <Select
                            isMulti
                            {...register("specialTag")}
                            defaultValue={payload?.specialTag || []}
                            value={payload?.specialTag || []}
                            onChange={(opt) =>
                              handleSelectChange(opt, "specialTag", false)
                            }
                            options={initialSpecialProductTags}
                            name="specialTag"
                          />
                          <p className="text-danger err-message">
                            {cerrors || ""}
                            {/* {errors.category?.message} */}
                          </p>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span>*</span> Box Category
                          </Label>
                          <Select
                            isMulti
                            {...register("category")}
                            defaultValue={payload?.setCategory || []}
                            value={boxCategoryList?.filter((cat) =>
                              payload?.category?.includes(cat?.value)
                            )}
                            onChange={(opt) =>
                              handleSelectChange(opt, "category", true)
                            }
                            options={boxCategoryList}
                            name="category"
                          />
                          <p className="text-danger err-message">
                            {cerrors || ""}
                          </p>
                        </div>
                      </div>
                    </FormGroup>

                    {/* Price category start from here  */}
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span>*</span> Price Category
                          </Label>
                          <Select
                            {...register("priceCategory")}
                            defaultValue={payload?.priceCategory || ""}
                            value={priceCatOptions?.find(
                              (opt) => opt?.value === payload?.priceCategory
                            )}
                            onChange={handlePriceCategoryChange}
                            options={priceCatOptions}
                            name="priceCategory"
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            Box Profit Margin
                          </Label>

                          <input
                            // {...register('profitMargin')}
                            className="form-control"
                            type="text"
                            name="boxProfit"
                            defaultValue={payload?.boxProfit || boxProfit}
                            onChange={(e) => handleProfitMorgin(e)}
                          />
                          <p className="text-danger err-message">
                            {errors.name?.message}
                          </p>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div className="special_tag">
                          <Label className="col-form-label pt-0">
                            Box Level
                          </Label>
                          <input
                            className="form-control"
                            type="text"
                            name="boxlevel"
                            onChange={(e) => handleBoxLevel(e)}
                            defaultValue={payload.boxlevel || ""}
                          />
                          <p className="text-danger err-message">
                            {errors.boxlevel?.message}
                          </p>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl="6">
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span></span> Image
                          </Label>
                          <div className="file-upload">
                            <div className="file-select file_select">
                              <div className="file-select-button" id="fileName">
                                Choose File
                              </div>
                              <div className="file-select-name" id="noFile">
                                {editName || "No file chosen..."}
                              </div>
                              <input
                                {...register("logo")}
                                className="form-control"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                defaultValue={payload.Image || ""}
                                onChange={onSelectFile}
                              />
                              {(selectedFile || payload.image) && (
                                <img
                                  style={{ width: "40%", height: "inherit" }}
                                  src={preview || payload.image}
                                  alt="as"
                                />
                              )}
                              <p className="text-danger err-message">
                                {errors.Image?.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                    {/* ********************************************************************************** */}
                    {/* Featured cases start from here  */}
                    {/* ********************************************************************************** */}
                    {/* <FormGroup className='justify-content-start'>   
                      <div className='m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated'>
                        <Label className='col-form-label pt-0'>
                          Featured Cases
                        </Label>
                        <br />
                        <Label className='d-block hide_show'>
                          <input
                            className='radio_animated'
                            id='edo-ani'
                            type='radio'
                            name='featuredcase'
                            defaultValue={payload?.featuredcase || ""}
                            checked={
                              payload?.featuredcase === true ? true : false
                            }
                            onChange={(e) => handleFeaturedBox(e, true)}
                          />
                          <p className='text-danger'>
                            {errors.featuredcase?.message}
                          </p>
                          Yes
                        </Label>
                        <Label className='d-block hide_show'>
                          <input
                            className='radio_animated'
                            id='edo-ani1'
                            type='radio'
                            name='featuredcase'
                            checked={
                              payload?.featuredcase === false ? true : false
                            }
                            defaultValue={payload?.featuredcase || ""}
                            onChange={(e) => handleFeaturedBox(e, false)}
                          />
                          <p className='text-danger'>
                            {errors?.featuredcase?.message}
                          </p>
                          No
                        </Label>
                      </div>
                    </FormGroup> */}
                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div>
                          <Label className="col-form-label pt-0">
                            <span>*</span> Case Special Tag
                          </Label>
                          <Select
                            isMulti
                            {...register("caseSpecialTag")}
                            defaultValue={payload?.caseSpecialTag || []}
                            value={payload?.caseSpecialTag || []}
                            onChange={(opt) =>
                              handleSelectChange(opt, "caseSpecialTag", false)
                            }
                            options={specialOptions}
                            name="caseSpecialTag"
                          />
                          <p className="text-danger err-message">
                            {cerrors || ""}
                            {/* {errors.category?.message} */}
                          </p>
                        </div>
                      </div>
                    </FormGroup>

                    {/* ******************************************************************************************** */}
                    {/* Featured cases end from here  */}
                    {/* ***************************************************************************************** */}

                    <FormGroup className="justify-content-start">
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="col-form-label pt-0">Hide Box</Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="hideBox"
                            defaultValue={payload.hideBox || ""}
                            checked={payload?.hideBox === true ? true : false}
                            onChange={(e) => handleOnChangeVisibility(e, true)}
                          />
                          <p className="text-danger">
                            {errors.hideBox?.message}
                          </p>
                          Show
                        </Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="hideBox"
                            checked={payload.hideBox === false ? true : false}
                            defaultValue={payload.hideBox || ""}
                            onChange={(e) => handleOnChangeVisibility(e, false)}
                          />
                          <p className="text-danger">
                            {errors.hideBox?.message}
                          </p>
                          Hide
                        </Label>
                      </div>
                    </FormGroup>
                    <FormGroup className="justify-content-start">
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="col-form-label">For Box Roll</Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="freeBox"
                            defaultValue={payload.freeBox || ""}
                            checked={payload.freeBox === false ? true : false}
                            onChange={(e) => handleOnChangeFreeBox(e, false)}
                          />
                          <p className="text-danger">
                            {errors.freeBox?.message}
                          </p>
                          Paid
                        </Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="freeBox"
                            checked={payload?.freeBox === true ? true : false}
                            defaultValue={payload.freeBox || ""}
                            onChange={(e) => handleOnChangeFreeBox(e, true)}
                          />
                          <p className="text-danger">
                            {errors.freeBox?.message}
                          </p>
                          Free
                        </Label>
                      </div>
                    </FormGroup>
                    <FormGroup className="justify-content-start">
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="col-form-label pt-0">
                          Box appear on battle
                        </Label>
                        <br />
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="boxBattle"
                            defaultValue={payload.boxBattle || ""}
                            checked={payload?.boxBattle === true ? true : false}
                            onChange={(e) => handleBoxBattle(e, true)}
                          />
                          <p className="text-danger">
                            {errors.hideBox?.message}
                          </p>
                          Yes
                        </Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="boxBattle"
                            checked={payload.boxBattle === false ? true : false}
                            defaultValue={payload.boxBattle || ""}
                            onChange={(e) => handleBoxBattle(e, false)}
                          />
                          <p className="text-danger">
                            {errors.hideBox?.message}
                          </p>
                          No
                        </Label>
                      </div>
                    </FormGroup>

                    <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div className="desc">
                          <Label className="col-form-label pt-0">
                            Description
                          </Label>
                          <textarea
                            {...register("description")}
                            className="form-control"
                            type="text"
                            defaultValue={payload.description || ""}
                          />
                        </div>
                      </div>
                    </FormGroup>

                    {/* <FormGroup className="justify-content-start">
                      <div className="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                        <Label className="col-form-label">Box Quantity</Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani"
                            type="radio"
                            name="enableBox"
                            defaultValue={payload.enableBox || ""}
                            checked={payload.enableBox === true ? true : false}
                            onChange={(e) => handleOnChangeVisible(e, true)}
                          />
                          <p className="text-danger">
                            {errors.enableBox?.message}
                          </p>
                          enable
                        </Label>
                        <Label className="d-block hide_show">
                          <input
                            className="radio_animated"
                            id="edo-ani1"
                            type="radio"
                            name="enableBox"
                            checked={
                              payload?.enableBox === false ? true : false
                            }
                            defaultValue={payload?.enableBox || ""}
                            onChange={(e) => handleOnChangeVisible(e, false)}
                          />
                          <p className="text-danger">
                            {errors.enableBox?.message}
                          </p>
                          disable
                        </Label>
                      </div>
                    </FormGroup> 
                    {/* {payload?.enableBox && (
                      <FormGroup className="justify-content-start">
                        <div className="admin-input col-xl-10 col-md-7">
                          <div className="special_tag">
                            <Label className="col-form-label pt-0">
                              Box quantity
                            </Label>

                            <input
                              className="form-control"
                              type="text"
                              name="boxQuantity"
                              defaultValue={
                                payload.boxQuantity >= 0
                                  ? payload.boxQuantity
                                  : ""
                              }
                              onChange={(e) => handleBoxQuantity(e)}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    )} */}

                    {/* <FormGroup className="justify-content-start">
                      <div className="admin-input col-xl-10 col-md-7">
                        <div className="special_tag">
                          <Label className="col-form-label pt-0">
                            Box timer
                          </Label>

                          <input
                            className="form-control"
                            type="datetime-local"
                            name="boxTimer"
                            value={payload?.boxTimer}
                            defaultValue={payload?.boxTimer || ""}
                            onChange={(e) => handleBoxTimer(e)}
                          />
                        </div>
                      </div>
                    </FormGroup> */}
                  </Col>
                </Row>

                <FormGroup className="search_boxes_section search-box-section justify-content-center">
                  <div className="row">
                    <div className="addbox-input col-xl-4 col-md-4">
                      <Label className="col-form-label pt-0">
                        <span></span> Search Here
                      </Label>
                      <input
                        type="text"
                        placeholder="Search"
                        name="title"
                        onChange={(e) => handleSearchProduct(e)}
                        class="form-control"></input>
                      <i class="fa fa-search search-icon"></i>
                    </div>
                    <div className="addbox-input col-xl-4 col-md-4">
                      <Label className="col-form-label pt-0">
                        <span></span> Min
                      </Label>
                      <i class="fa fa-dollar dollar-icon"></i>
                      <input
                        name="min"
                        onChange={(e) => handleSearchProduct(e)}
                        className="form-control"
                        type="number"
                        placeholder="Min"
                      />
                    </div>

                    <div className="addbox-input col-xl-4 col-md-4">
                      <Label className="col-form-label pt-0">
                        <span></span> Max
                      </Label>
                      <i class="fa fa-dollar dollar-icon"></i>
                      <input
                        name="max"
                        onChange={(e) => handleSearchProduct(e)}
                        className="form-control"
                        type="number"
                        placeholder="Max"
                      />
                    </div>

                    <div className="addbox-input col-xl-12 col-md-12">
                      <div className="search-section">
                        <div className="search-category search_category">
                          <select
                            class="category-search"
                            name="category"
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=""> Choose Category</option>
                            {categoryList &&
                              categoryList.length > 0 &&
                              categoryList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                          <select
                            class="category-search"
                            name="rarity"
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=""> Choose Rarity</option>
                            {rarityList &&
                              rarityList.length > 0 &&
                              rarityList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                          <select
                            class="category-search"
                            name="itemType"
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value="">Choose Item Type</option>
                            {itemsType &&
                              itemsType.length > 0 &&
                              itemsType.map((el) => (
                                <option value={el?.value}>{el?.label}</option>
                              ))}
                          </select>
                          {/* <select
                            class="category-search"
                            name="partnerId"
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=""> Select Partner</option>
                            {selectePartnerList &&
                              selectePartnerList.length > 0 &&
                              selectePartnerList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                          <select
                            class="category-search"
                            name="brandId"
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=""> Select Brand </option>
                            {selectebrandList &&
                              selectebrandList.length > 0 &&
                              selectebrandList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select> */}
                        </div>

                        <div className="searched-content-section">
                          <div class="row">
                            {productList.length === 0 &&  <p className="noProd">No Product Found</p>}
                            {productList.map((el) => {
                              const isSelected = chooseProduct
                                ?.map((item) => item?.pid)
                                .includes(el?._id);
                              const color = el?.rarityLevel?.color;
                              return (
                                <div className="col-md-2">
                                  <div
                                    className={`search-list ${
                                      isSelected ? "itemselected" : ""
                                    }`}
                                    style={{
                                      background: `linear-gradient(180deg, ${color}bf 50%, ${color} 100%)`,
                                    }}>
                                    <div
                                      className="middle-btn-selected"
                                      onClick={() =>
                                        handleProduct(el, el._id, isSelected)
                                      }>
                                      <span>
                                        {isSelected ? "Selected" : "Select"}
                                      </span>
                                    </div>
                                    <img
                                      src={
                                        el.bannerImage
                                          ? el.bannerImage
                                          : defaultproductimg
                                      }
                                      alt="No ImGE fOUND"
                                    />
                                    <h3 className="search-title">{el.name}</h3>
                                    <p className="search-price">$ {el.price?.toFixed(2)}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div class="row">
                            <div className="col-xl-12">
                              <div class="text-center search-pagination">
                                <ReactPaginate
                                  breakLabel="..."
                                  nextLabel=""
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={10}
                                  pageCount={pageCount}
                                  previousLabel=""
                                  renderOnZeroPageCount={null}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="row justify-content-center">
                  <div className="addbox-input col-xl-12 col-md-12">
                    <div className="search-section">
                      <div className="choose-odds boxthead">
                        <div className="row">
                          <div class="col-md-6">
                            <div className="choose-odds-content">
                              <div className="boxtbody">
                                <h5>Item Image</h5>
                              </div>
                              <div className="boxtbody">
                                <h5>Item Name</h5>
                              </div>
                              <div className="boxtbody">
                                <h5>Item Price</h5>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div className="choose-odds-content">
                              <div className="boxtbody">
                                <h5> Item Price (In Cases)</h5>
                              </div>
                              <div className="boxtbody">
                                <h5> Inexpensive Item</h5>
                              </div>
                              <div className="boxtbody">
                                <h5>Item Odds(%)</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {products &&
                    products.length > 0 &&
                    products.map((el, index) => (
                      <div className="addbox-input col-xl-12 col-md-12">
                        <div className="search-section">
                          <div className="choose-odds">
                            <div className="row">
                              <div class="col-md-6">
                                <div className="choose-odds-content">
                                  <div className="odds-icon">
                                    <img
                                      src={el?.bannerImage || defaultproductimg}
                                      alt=""
                                      hight="50"
                                      width="50"
                                    />
                                  </div>
                                  <div>
                                    <h5>{el?.name || ""}</h5>
                                  </div>
                                  <div>
                                    <span>{el?.price || ""} $</span>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div className="choose-odds-content">
                                  <div>
                                    <span>
                                      <input
                                        type="text"
                                        name="addonBlance"
                                        placeholder="0.00"
                                        value={singleboxPriceCalculation(
                                          chooseProduct[index].productPrice,
                                          chooseProduct[index]?.chance
                                        )}
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <div className="form-check expensive">
                                      <input
                                        className="form-check-input customCheckbox"
                                        type="checkbox"
                                        onChange={() =>
                                          handleExpensiveItems(index)
                                        }
                                        checked={
                                          !!inexpensiveItems[
                                            chooseProduct[index]?.pid
                                          ]
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <NumberFormat
                                      thousandSeparator
                                      allowNegative={false}
                                      type="text"
                                      placeholder="0.00"
                                      name="chance"
                                      onChange={(e) =>
                                        handleOnchangePro(e.target, index)
                                      }
                                      value={chooseProduct[index]?.chance || ""}
                                    />

                                    <Button
                                      className="del-icon"
                                      onClick={() => handleRemove(index)}>
                                      <i class="fa fa-trash"></i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </FormGroup>
                <div className="form-button d-flex justify-content-between submit-btns pt-3">
                  <div className="col-lg-10 submit-btns-inner">
                    <Button
                      color="primary"
                      type="button"
                      className="mr-3 md-mb-3 perc_btn">
                      Total Box Price :{" "}
                      <span>$ {`${payload?.boxPrice || bxPrice}`}</span>
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      className="mr-3 md-mb-3 perc_btn">
                      Total odds : <span>{` ${totalOdds || 0}`} %</span>
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      className="mr-2 perc_btn">
                      Remaining odds : <span>{` ${remainOdds || 0}`} %</span>
                    </Button>
                    <Button color="primary" type="button" className="perc_btn">
                      Total items :{" "}
                      <span>{` ${chooseProduct?.length || 0}`}</span>
                    </Button>
                  </div>
                  <div className="col-lg-2 justify-content-end sbmit-btn">
                    <Button
                      color="primary"
                      type="submit"
                      className="perc_btn main-btn">
                      Submit
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
export default AddBoxForm;
