//import React, {  useEffect, useState } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
//import {userInstance} from '../../config/axios';

const Breadcrumb = ({ title, parent }) => {
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6">
            <div className="page-header-left">
              <h3>
                {title}
                <small>Winpeer Admin panel</small>
              </h3>
            </div>
          </Col>
          <Col lg="6">
            <ol className="breadcrumb pull-right">
              <li className="breadcrumb-item tab-itme">
                <Link to="/dashboard">
                  <Home />
                </Link>
              </li>
              <li className="breadcrumb-item tab-itme">
                <Link to="/order-management">{parent}</Link>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumb;
