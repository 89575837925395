import React, { useEffect, useState } from "react";
// import ordered from "../../assets/images/dashboard/designer.jpg";
import { queryString } from "../../utils/queryString";
import Breadcrumb from "../common/breadcrumb";
import { getOrderDetailsByOrderId } from "./../../reducers/adminReducers";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { Button } from "react-bootstrap";
import Loader from "../loader/loader";
import logo from "../../assets/images/boxilogof.png";
const InvoiceDetail = () => {
  const { id } = queryString();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { orderDetails } = result;
  const { orderSummary, orderInfo } = orderDetails || {};

  const [loading, setLoading] = useState(false);
  // console.log("Product NAME===>", orderSummary)
  // console.log("orderDetails==>>1234", orderDetails);

  const createAddress = (data) => {
    let address = "";
    if (data) {
      Object.keys(data).forEach((el) => {
        if (data[el]) {
          address = `${address ? `${address},` : address} ${data[el]}`;
        }
      });
    }

    // console.log("createAddress", address);
    return address;
  };

  const getOrderDetailsByIdInvoice = async () => {
    setLoading(true);
    const response = await dispatch(getOrderDetailsByOrderId(id));
    // console.log("Check RESPONSE11", response)
    if (response?.payload) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetailsByIdInvoice();
    // if (id) {
    //   // setLoading(true)
    //   dispatch(getOrderDetailsByOrderId(id));
    //   //setLoading(false)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const total = () => {
    // console.log("I am here 123456789");
    const s = orderSummary?.reduce(
      (s, { price, quantity }) => s + price * quantity,
      0
    );
    return s;
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {loading && <Loader />}
      <div className="admin-order-invoice">
        <Breadcrumb title="orderdetail" parent="order-management" />
        <Button onClick={handlePrint}>Print</Button>
      </div>
      <div className="order-invoice-template">
        <div>
          <table
            style={{
              width: "100%",
              height: "auto",
              textAlign: "center",
            }}
            border={0}
            cellSpacing={0}
          >
            <tbody style={{ background: "#ffff", padding: "20px" }}>
              <tr>
                <td colSpan={2}>
                  <h1>Report</h1>
                </td>
                <td colSpan={2} style={{ textAlign: "right" }}>
                  <img src={logo} alt="logo" />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <h5 style={{ marginBottom: "0px" }}>Winpeer</h5>
                  <p>ADDRESS</p>
                  <p>United States</p>
                </td>
              </tr>
              {/* {orderSummary?.length > 0 &&orderSummary?.map((el) => ( */}
              <tr className="address-section">
                <td colSpan={2}>
                  <h6>INVOICE</h6>
                  <p>{createAddress(orderInfo?.shippingAddress)}</p>
                  <p>
                    <strong>Mobile :</strong> {orderInfo?.userId?.mobile}
                  </p>
                </td>
                {/* {console.log("Mobile No:-----",el)} */}
                <td colSpan={2} style={{ textAlign: "left" }}>
                  <div className="invoice-no">
                    <h6>Order Id</h6> <span>{orderInfo?.orderid}</span>
                  </div>
                  <div className="invoice-no">
                    {" "}
                    <h6>DATE </h6>{" "}
                    <span>
                      {moment(orderInfo?.updatedAt).format("DD MMMM YYYY")}
                    </span>
                  </div>
                </td>
              </tr>
              {/* ))} */}
            </tbody>
          </table>
          {/* <table
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "#fff",
              textAlign: "center",
              padding: "10px",
              background: "#fafafa",
            }}
          >
            <tbody>
              <tr style={{ color: "#6c757d", fontSize: "20px" }}>
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontSize: "12px",
                    fontWeight: "700",
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  Order Date
                </td>
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontSize: "12px",
                    fontWeight: 700,
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  Order No.
                </td>
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontSize: "12px",
                    fontWeight: "700",
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  Payment
                </td>
                <td
                  style={{
                    width: "25%",
                    fontSize: "12px",
                    fontWeight: "700",
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  Shipping Address
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  fontSize: "12px",
                  color: "#262626",
                }}
              >
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontWeight: "bold",
                    background: "#fafafa",
                  }}
                >
                  {" "}
                  {moment(orderInfo?.updatedAt).format("DD MMMM YYYY")}{" "}
                </td>
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontWeight: "bold",
                    background: "#fafafa",
                  }}
                >
                  {orderInfo?.orderid}
                </td>
                <td
                  style={{
                    borderRight: "1.5px dashed  #DCDCDC",
                    width: "25%",
                    fontWeight: "bold",
                    background: "#fafafa",
                  }}
                >
                  CASH
                </td>
                {console.log("ADDRESS CHECK ", orderInfo?.shippingAddress)}
                <td
                  style={{
                    width: "25%",
                    fontWeight: "bold",
                    background: "#fafafa",
                  }}
                >
                  {createAddress(orderInfo?.shippingAddress)}
                </td>
              </tr>
            </tbody>
          </table> */}
          <table
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "#fff",
              padding: "20px",
              fontSize: "12px",
              marginTop: "60px",
            }}
          >
            {/* {orderSummary?.length > 0 && orderSummary?.map((el)=>  */}
            <thead>
              <tr
                style={{
                  color: "#6c757d",
                  fontWeight: "bold",
                  padding: "5px",
                  border: "1.8px solid #ed0f0f",
                  borderLeft: "0px",
                  borderRight: "0px",
                }}
              >
                <td style={{ padding: "10px", textAlign: "left" }}>
                  {" "}
                  Quantity
                </td>
                <td style={{ textAlign: "right", padding: "10px" }}>
                  designation
                </td>
                <td style={{ textAlign: "right", padding: "10px" }}>
                  PRIX UNIT
                </td>
                <td style={{ textAlign: "right", padding: "10px" }}>Amount</td>
              </tr>
            </thead>
            <tbody>
              {orderSummary?.length > 0 &&
                orderSummary?.map((el) => (
                  <tr>
                    <td style={{ width: "10%" }}> {el?.quantity}</td>

                    <td
                      style={{
                        width: "20%",
                        marginLeft: "10px",
                        textAlign: "center",
                      }}
                    >
                      {el?.productId?.name}
                    </td>

                    <td
                      style={{
                        width: "20%",
                        padding: "10px",
                        textAlign: "right",
                        color: "black",
                      }}
                    >
                      {el?.price}
                    </td>
                    <td
                      style={{
                        width: "30%",
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "right",
                      }}
                    >
                      {el?.price * el?.quantity} $
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: "#fff",
              padding: "20px",
              fontSize: "12px",
              marginTop: "100px",
            }}
          >
            <tbody>
              <tr style={{ padding: "20px", color: "#000", fontSize: "15px" }}>
                <td></td>
                <td></td>
                <td
                  style={{
                    fontWeight: "bold",
                    paddingTop: "100px",
                    textAlign: "right",
                  }}
                >
                  <h6>TOTAL TTC</h6>
                </td>

                <td
                  style={{
                    textAlign: "right",
                    padding: "5px 0px",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  <h6>{total()} $</h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default InvoiceDetail;
