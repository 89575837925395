import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Table } from "reactstrap";
import { Label } from "reactstrap";
import { queryString } from "../../../utils/queryString";
import { updateLatestItemPrice } from "../../../reducers/adminReducers";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const PriceList = () => {
  const result = useSelector((state) => state.admin);
  const history = useHistory();
  const { latestPriceList } = result || {};
  const [price, setPrice] = useState();
  const { id: productId } = queryString();
  const dispatch = useDispatch();
  const prodPrice = latestPriceList[latestPriceList.length - 1]?.[1];
  const handleUpdatePrice = () => {
    const payload = {
      productId,
      productPrice: price > 0 ? Number(price) : prodPrice,
    };
    dispatch(updateLatestItemPrice(payload)).then((res) => {
      if (res?.payload?.resultData?.data?.code === 200) {
        toast.success("Latest Price Updated Succussfully", {
          toastId: "latestPrice",
        });
        history.push(`/products/list_product`, { updated: true, productId });
      }
    });
  };
  return (
    <Container fluid={false}>
      <Table bordered style={{ width: "80%", margin: "25px auto" }}>
        <thead style={{ backgroundColor: "#f8f9fa" }}>
          <tr>
            <th style={{ width: "30px" }}>S.No</th>
            <th style={{ width: "200px" }}>Month, Date & Year</th>
            <th style={{ width: "100px" }}>Price</th>
          </tr>
        </thead>
        <tbody>
          {latestPriceList?.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item[0]}</td>
              <td>$ {item[1]?.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p className="text-center mt-4 mb-2">OR</p>

      <div
        className="admin-input col-xl-5 col-md-7"
        style={{ marginLeft: "110px", marginBottom: "20px" }}>
        <Label className="col-form-label pt-0">Update Price Manually</Label>
        <input
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="form-control"
          type="number"
        />

        <button onClick={handleUpdatePrice} className="btn btn-primary mt-4">
          Update
        </button>
      </div>
    </Container>
  );
};

export default PriceList;
