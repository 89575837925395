import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import TeamListDataTable from './teamListDataTable';
import { getAllTeamsData } from '../../reducers/adminReducers';
import Loader from '../loader/loader';
//import { adminInstance } from '../../config/axios';
const TeamManagement = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { allTeamData } = result || {};
	const [loading, setLoading] = useState(false);
	const getAllTeamdata = async () => {
		setLoading(true)
		const response = await dispatch(getAllTeamsData())
		if (response?.payload) {
			setLoading(false)
		}
	}
	useEffect(() => {
		getAllTeamdata()
		// setLoading(true);
		// dispatch(getAllTeamsData());
		// setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<>
			<Fragment>
				<Breadcrumb title="Team Management" parent="Team" />
				<Container fluid={true}>
					<div className="admin-card">
						<Card>
							<CardHeader>
								<h5>All Teams </h5>
							</CardHeader>
							<CardBody>
								<div className="btn-popup pull-right">
									<Link to="/addTeam" className="btn btn-secondary main-btn">
										Create Teams
									</Link>
								</div>
								<div className="clearfix"></div>
								<div
									id="batchDelete"
									className="category-table user-list order-table coupon-list-delete"
								>
									{!loading ? (
										allTeamData.length > 0 && (
											<TeamListDataTable
												multiSelectOption={true}
												myData={allTeamData}
												pageSize={10}
												pagination={true}
												class="-striped -highlight"
											/>
										)
									) : (
										<Loader />
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</Container>
			</Fragment>
		</>
	);
};
export default TeamManagement;
