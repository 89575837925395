import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Link } from 'react-router-dom';
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { useSelector } from 'react-redux';
import { adminInstance } from '../../../config/axios';
// import { getAllRarityList } from '../../../reducers/adminReducers'
import ListDatatable from './listdatatable';
import DeleteConfirmPopup from '../../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Loader from '../../loader/loader';
const Productrarity = () => {
	const history = useHistory();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [RarityList, setRarityList] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getAllRarityData = async () => {
		try {
			setLoading(true);
			const response = await adminInstance().get('api/admin/getRarity');
			const { code, rarity } = response.data;
			// console.log('response.data', response.data);
			if (code === 200) {
				setLoading(false);
				setRarityList(rarity);
			}
		} catch (error) { }
	};

	useEffect(() => {
		getAllRarityData();
	}, [dummy]);
	const handleOnDelete = async (id) => {
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteRarity/${id}`
			);
			setDummy(true);
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted');
				history.push('/products/rarity-list');
			} else {
				setDummy(false);
			}
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Rarity List" parent="Rarity" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>Rarity Details</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeRarityLevel'].includes(r)
							) && (
									<div className="btn-popup pull-right">
										<Link
											to="/products/add-raritylevel"
											className="btn btn-secondary main-btn"
										>
											Create Rarity
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{/* {console.log('ayushi',RarityList)} */}
								{!loading ? (
									RarityList.length > 0 && (
										<ListDatatable
											multiSelectOption={true}
											myData={RarityList}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setRarityList={setRarityList}
										/>
									)
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default Productrarity;
