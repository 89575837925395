import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
// import TeamListDataTable from './levelListDataTable';
import { getReferrerUsers } from '../../reducers/adminReducers';
import Loader from '../loader/loader';
import AffiliateUserListDataTable from './affiliateUserDataTable';
//import { adminInstance } from '../../config/axios';
const AffiliateUserManagement = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { referrerList } = result || {};
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
        const getAllTeamdata = async () => {
            setLoading(true)
            const response = await dispatch(getReferrerUsers())
            if (response?.payload) {
                setLoading(false)
            }
        }
		getAllTeamdata()
	}, [dispatch]);

	return (
		<>
			<Fragment>
				<Breadcrumb title="Team Management" parent="Team" />
				<Container fluid={true}>
					<div className="admin-card">
						<Card>
							<CardHeader>
								<h5>All Affiliate Users </h5>
							</CardHeader>
							<CardBody>
								{/* <div className="btn-popup pull-right">
									<Link to="/addAffiliateLevel" className="btn btn-secondary">
										Create Affiliate Level
									</Link>
								</div> */}
								<div className="clearfix"></div>
								<div
									id="batchDelete"
									className="category-table user-list order-table coupon-list-delete"
								>
									{!loading ? (
										referrerList.length > 0 && (
											<AffiliateUserListDataTable
												multiSelectOption={true}
												myData={referrerList}
												pageSize={10}
												pagination={true}
												class="-striped -highlight"
											/>
										)
									) : (
										<Loader />
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</Container>
			</Fragment>
		</>
	);
};
export default AffiliateUserManagement;
