import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import ListDatatable from './listDatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCategoryList } from '../../reducers/adminReducers';
import '../../assets/styles/admin.css';
import Loader from '../loader/loader';

const Productcategory = () => {
	const result = useSelector((state) => state.admin);
	const { categoryList, loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getAllCategoryListData = async () => {
		setLoading(true)
		const response = await dispatch(getAllCategoryList())
		// console.log('check the CategoryList given response or not', response?.payload)
		if (response?.payload) {
			setLoading(false)
		}
	}
	useEffect(() => {
		getAllCategoryListData()
		// setLoading(true);
		// dispatch(getAllCategoryList());
		// setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dummy]);
	const handleOnDelete = async (id) => {
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteCategory/${id}`
			);
			setDummy(true);
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted', { toastId: 1 });
			} else {
				setDummy(false);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Category List" parent="Category" />
			<Container fluid={true} className="index-page">
				<div className="admin-card">
					{/* {loderStatus && <Loader /> } */}
					<Card>
						<CardHeader>
							<h5>Category Details</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeCategory'].includes(r)
							) && (
									<div className="btn-popup pull-right admin-category">
										<Link to="/create-category" className="btn btn-secondary main-btn">
											Create Category
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									categoryList && categoryList.length > 0 ?
										<ListDatatable
											multiSelectOption={true}
											myData={categoryList}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
										/>
										: <h1>Data does not found</h1>
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default Productcategory;
