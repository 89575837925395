import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmForSelected from "../common/deleteConfirmForSelected";
import {
  deleteSelectedWithdrawRequest,
  withdrawPaymentTransactions,
  withdrawDeclineByAdmin,
  WithdrawFromAdmin,
  getWithdrawalRequests,
} from "../../reducers/adminReducers";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const ListDatatable = ({ myData, myClass }) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);

  const { loginAdmin } = result || {};
  const { role } = loginAdmin;
  const [data, setData] = useState();
  const [selectedBox, setSelectedBox] = useState([]);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(true);
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [declineMessage, setDeclineMessage] = useState("");
  const [isMessageValid, setIsMessageValid] = useState(false);

  useEffect(() => {
    setData(myData.data);
  }, [myData]);

  const columnDefs = [
    {
      name: "User Name",
      selector: (row) => row?.username,
      sortable: true,
    },
    {
      name: "Order Id",
      selector: (row) => row?.orderId,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => `$${row?.amountInUsd}`,
      sortable: true,
    },
    {
      name: "Currency Amt",
      selector: (row) => `${row?.cryptoAmt || ""} ${row?.payment || ""}`,
    },
    {
      name: "User Type",
      selector: (row) => row?.userType,
      sortable: true,
    },
    {
      name: "Payment Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="flex justify-between">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setConfirmDeleteShow({ type: "Approve", row })}
          >
            Approve
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => setConfirmDeleteShow({ type: "Decline", row })}
          >
            Decline
          </button>
        </div>
      ),
      sortable: true,
    },
  ];

  const handleSelectFunds = (row) => {
    let selectedPro = [];
    if (!row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
    if (row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
  };

  const handleSelectedDelete = async (e, id) => {
    e.preventDefault();
    const res = await dispatch(deleteSelectedWithdrawRequest(id));
    const { resultData } = res?.payload;
    if (resultData?.code === 200) {
      dispatch(getWithdrawalRequests());
      setSelectedRows(false);
      setOpenDelPopup(false);
    }
  };
  const onCloseModal = () => {
    setOpenDelPopup(false);
  };
  const selectDeleteOpen = (e) => {
    e.preventDefault();
    if (selectedBox.length > 0) {
      setOpenDelPopup(true);
    }
  };

  const handleTextareaChange = (e) => {
    setDeclineMessage(e.target.value);
    setIsMessageValid(!!e.target.value.trim());
  };

  const handleWidthdrawRejectFromAdmin = async (row, message) => {
    try {
      const response = await dispatch(
        withdrawDeclineByAdmin({
          orderId: row?.orderId,
          msg: message,
        })
      );
      if (response.payload.resultData) {
        toast.success(response.payload.resultData.msg, { id: "success" });
        dispatch(withdrawPaymentTransactions("withdrawal"));
      } else {
        toast.error(response.payload.resultData.msg, { id: "error" });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        id: "error",
      });
    }
  };
  const handleWidthdrawAmountFromAdmin = async (row) => {
    try {
      const response = await dispatch(
        WithdrawFromAdmin({
          orderId: row?.orderId,
        })
      );
      if (response.payload.resultData) {
        toast.success(response.payload.resultData.msg, { id: "success" });
        dispatch(withdrawPaymentTransactions("withdrawal"));
      } else {
        toast.error(response.payload.resultData.msg, { id: "error" });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        id: "error",
      });
    }
  };

  return (
    <div>
      <DeleteConfirmForSelected
        open={openDelPopup}
        handleSelectedDelete={handleSelectedDelete}
        onCloseModal={onCloseModal}
        id={selectedBox}
      />
      {role?.some((r) => ["suUsers", "superAdmin", "delFunds"].includes(r)) && (
        <div className="btn-popup pull-right">
          <span
            className="btn btn-secondary del-btn"
            onClick={selectDeleteOpen}
          >
            Delete
          </span>
        </div>
      )}
      <Modal
        show={confirmDeleteShow}
        onHide={() => setConfirmDeleteShow(false)}
        size="md"
        centered
        className="text-dark text-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>{confirmDeleteShow?.row?.username}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark">
          <h4>OrderId: {confirmDeleteShow?.row?.orderId}</h4>
          {confirmDeleteShow?.type === "Decline" ? (
            <div>
              <textarea
                rows="5"
                className="form-control"
                placeholder="Enter decline message"
                value={declineMessage}
                onChange={handleTextareaChange}
                required
              />
              <h5 className="text-dark">
                Are you sure you want to Reject this request?
              </h5>
              {!isMessageValid && (
                <small className="text-danger">Please enter a message</small>
              )}
            </div>
          ) : (
            <div>
              <h5 className="text-dark">
                Are you sure you want to Approved this request?
              </h5>
            </div>
          )}
          <div>
            <button
              className="btn btn-secondary"
              onClick={() => setConfirmDeleteShow(false)}
            >
              No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (confirmDeleteShow?.type === "Decline") {
                  handleWidthdrawRejectFromAdmin(
                    confirmDeleteShow?.row,
                    declineMessage
                  );
                } else {
                  handleWidthdrawAmountFromAdmin(confirmDeleteShow?.row);
                }
              }}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
          // searchData={searchData}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            selectableRows
            clearSelectedRows={selectedRows}
            onSelectedRowsChange={(e) => handleSelectFunds(e)}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};

// const ProfileImage = ({ row }) => {
//   return (
//     <div className='profile-image'>
//       <img
//         src={row.image ? row.image : placeholder}
//         alt='logo'
//         style={{ height: '20px', width: '20px' }}
//       />
//     </div>
//   )
// }

// const ActionButton = ({
//   row,
//   handleDelete,
//   handleEdit,
//   handleAppoved,
//   role,
// }) => {
//   const writeRole = role?.some((r) =>
//     ["suUsers", "superAdmin", "writeFunds"].includes(r)
//   );
//   return (
//     <div className="action-btn">
//       <span>
//         <i
//           onClick={() =>
//             row.status === "pending"
//               ? writeRole
//                 ? handleAppoved(row._id, "declined")
//                 : toast.error(
//                     `You do not have permission to manage user funds`,
//                     {
//                       toastId: "permission",
//                     }
//                   )
//               : ""
//           }
//           className="fa fa-times"
//           style={{
//             width: 35,
//             fontSize: 20,
//             padding: 11,
//             color: "#e4566e",
//           }}
//         ></i>
//       </span>
//       <span>
//         <i
//           onClick={() =>
//             row.status === "pending"
//               ? writeRole
//                 ? handleAppoved(row._id, "success")
//                 : toast.error(
//                     `You do not have permission to manage user funds`,
//                     {
//                       toastId: "permission",
//                     }
//                   )
//               : ""
//           }
//           className="fa fa-check"
//           style={{
//             width: 35,
//             fontSize: 20,
//             padding: 11,
//             color: "rgb(40, 167, 69)",
//           }}
//         ></i>
//       </span>
//     </div>
//   );
// };

export default ListDatatable;
