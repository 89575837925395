import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import VendordataTable from './vendorDatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import '../../assets/styles/admin.css';
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';

const List_vendors = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [vendorsLists, setVendorsLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getAllVendorsData = async () => {
		try {
			setLoading(true);
			const response = await adminInstance().get('api/admin/getPartners');
			const { code, partners } = response.data;
			if (code === 200) {
				setLoading(false);
				setVendorsLists(partners);
			}
		} catch (error) {
			console.log('----------->>>', error);
		}
	};

	useEffect(() => {
		getAllVendorsData();
	}, [dummy]);
	const handleOnDelete = async (id) => {
		try {
			setDummy(false);
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/removerPartner/${id}`
			);
			const { code } = response.data;
			if (code === 200) {
				setDummy(true);
				toast.success('Successfully Deleted');
				// getAllVendorsData();
			} else {
				setDummy(false);
			}
		} catch (error) {
			console.log('----------->>>', error);
		}
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Partner List" parent="Partner" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>Partner Details</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writePartner'].includes(r)
							) && (
									<div className="btn-popup pull-right">
										<Link
											to="/vendors/create-vendors"
											className="btn btn-secondary main-btn"
										>
											Create Partner
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									vendorsLists.length > 0 && (
										<VendordataTable
											multiSelectOption={true}
											myData={vendorsLists}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setVendorsLists={setVendorsLists}
										/>
									)
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Partener'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};

export default List_vendors;
