import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { deleteSelectedUsers } from '../../reducers/adminReducers';
import { adminInstance } from '../../config/axios';
import { formatDate, formatDateTime } from '../../utils/multiuseFunctions.js';
const ListDataTable = ({
  myData,
  myClass,
  setUsersLists,
  selectedTab,
  handleDetailPopupOpen,
}) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  const [data, setData] = useState();
  useEffect(() => {
    setData(myData);
  }, [myData]);
  const [selectedBox, setSelectedBox] = useState([]);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(true);

  let delRole = role?.some((r) =>
    ['suUsers', 'superAdmin', 'delUsers'].includes(r)
  );
  let editRole = role?.some((r) =>
    ['suUsers', 'superAdmin', 'writeUsers'].includes(r)
  );

  const columnDefs = [
    {
      name: 'S.No',
      selector: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: 'Name',
      selector: (row) =>
        console.log('row', row) ||
        `${row?.kycDetails?.firstName || ''} ${
          row?.kycDetails?.lastName || ''
        }`,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row?.kycDetails?.phone,
      sortable: true,
    },
    {
      name: 'Date of Birth',
      selector: (row) => formatDate(row?.kycDetails?.dateOfBirth),
      sortable: true,
    },
    {
      name: 'Approve Date',
      selector: (row) =>
        row.status === 'Approved' ? formatDateTime(row?.kycVerifiedAt) : '',
      omit: selectedTab !== 'Approved', // Hide the column if not "Approved"
    },
    {
      name: 'Denied Date',
      selector: (row) =>
        row.status === 'Denied'
          ? formatDateTime(row?.kycVerifiedAt) || 'N/A'
          : '',
      omit: selectedTab !== 'Denied', // Hide the column if not "Denied"
    },
    {
      name: 'Applied',
      selector: (row) => formatDateTime(row?.kycSubmittedAt),
      sortable: true,
    },
    {
      name: 'Details',
      cell: (row) => (
        <div onClick={() => handleDetailPopupOpen(row)}>
          <EyeIcon />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const check = delRole || editRole;
    if (!check) {
      columnDefs.pop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefs]);

  const handleSelectUsers = (row) => {
    let selectedPro = [];
    if (!row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
    if (row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
  };
  const handleSelectedDelete = async (e, id) => {
    e.preventDefault();
    const res = await dispatch(deleteSelectedUsers(id));
    const { resultData } = res?.payload;
    if (resultData?.code === 200) {
      const response = await adminInstance().get('api/admin/getUserData');
      const { code, userList } = response?.data;
      if (code === 200) {
        setUsersLists(userList);
        setOpenDelPopup(false);
        setSelectedRows(false);
      }
    }
  };

  const onCloseModal = () => {
    setOpenDelPopup(false);
  };

  return (
    <div>
      <DeleteConfirmForSelected
        open={openDelPopup}
        handleSelectedDelete={handleSelectedDelete}
        onCloseModal={onCloseModal}
        id={selectedBox}
      />
      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}
        >
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            // selectableRows
            clearSelectedRows={selectedRows}
            onSelectedRowsChange={(e) => handleSelectUsers(e)}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};
const EyeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      fill='currentColor'
      class='bi bi-eye'
      viewBox='0 0 16 16'
    >
      <path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
      <path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
    </svg>
  );
};
export default ListDataTable;
