import React, { useState } from "react";
import {
  Button,
  // Col,
  //Input,
  //Label,
  //Row,
  Table,
  //Form,
  //FormGroup,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import {
	getAdminData,
} from '../../reducers/adminReducers';

const EditProfile = () => {
  const dispatch = useDispatch();

  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { firstname, lastname, email, role } = loginAdmin || {};
  const data = {
    firstname: firstname || "",
    lastname: lastname || "",
    email: email || "",
    id: loginAdmin?._id,
  };
  const [payload, setPayload] = useState(data);
  const handleUpdate = async () => {
    const response = await adminInstance().post(
      "api/admin/updateAdminProfile",
      payload
    );
    const { code, msg } = response.data;

    if (code === 200) {
      toast.success(msg);
    }
    dispatch(getAdminData());
  };
  const handleOnChange = (e) => {
    const { name, value } = e || {};
    setPayload({ ...payload, [name]: value });
  };

  return (
    <div className='tab-pane fade show active profile_pages'>
      {role?.some((r) =>
        ["suUsers", "superAdmin", "writeProfile"].includes(r)
      ) && (
        <div className='edit_btn'>
          <h5 className='f-w-600 f-16'>Profile</h5>
          {/* <i
            onClick={handleEdit}
            className='fa fa-pencil-square-o'
            style={{
              width: "unset",
              fontSize: 18,
              padding: 11,
              color: "#e89c31",
            }}>
            <span>Edit</span>
          </i> */}
        </div>
      )}

      <div className='table-responsive profile-table profile_table'>
        <Table className='table-responsive'>
          <tbody>
            <tr>
              <td>Email:</td>
              <td>
                {" "}
                <input
                  className='form-control'
                  type='text'
                  name='email'
                  value={payload?.email || ""}
                  onChange={(e) => handleOnChange(e.target)}
                />{" "}
              </td>
            </tr>
            <tr>
              <td>First Name:</td>
              <td>
                {" "}
                <input
                  className='form-control'
                  type='text'
                  name='firstname'
                  value={payload?.firstname || ""}
                  onChange={(e) => handleOnChange(e.target)}
                />{" "}
              </td>
            </tr>
            <tr>
              <td>Last Name:</td>
              <td>
                <input
                  className='form-control'
                  type='text'
                  name='lastname'
                  value={payload?.lastname || ""}
                  onChange={(e) => handleOnChange(e.target)}
                />{" "}
              </td>
            </tr>

            <tr>
              <td></td>
              <td>
                {}
                <div className='form-button d-flex justify-content-center pt-3'>
                  <Button type='submit' color='primary' className="main-btn" onClick={handleUpdate}>
                    Update
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EditProfile;
