import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Button, Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import { toast } from 'react-toastify';
import ListDataTable from './listDataTable';
import '../../assets/styles/admin.css';
import Loader from '../loader/loader';
import { Form, Modal } from 'react-bootstrap';
import { Tabs, TabList, Tab } from 'react-tabs';
import { CgClose } from 'react-icons/cg';
import { formatDate } from '../../utils/multiuseFunctions.js';

const Kyc_Request_List = () => {
  const [usersLists, setUsersLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(2);
  const [detailPopup, setDetailPopup] = useState(false);
  const [showImage, setShowImage] = useState();
  const [specificUserKycData, setSpecificUserKycData] = useState(null);
  const tabs = [
    { id: 'Approved', label: 'Approve' },
    { id: 'Denied', label: 'Denied' },
    { id: 'AdminVerification', label: 'Pending' },
  ];

  // Handler for tab selection

  const handleDetailPopupOpen = (data) => {
    setDetailPopup(true);
    setSpecificUserKycData(data);
  };
  const handleDetailPopupClose = () => {
    setDetailPopup(false);
    setSpecificUserKycData(null);
  };
  const getAllUsersData = async (tabValue) => {
    try {
      setLoading(true);
      const response = await adminInstance().get('api/admin/getUserKYCData', {
        params: {
          status: tabValue,
        },
      });

      const { code, userList } = response.data;
      if (code === 200) {
        setLoading(false);
        setUsersLists(userList);
      }
    } catch (error) {
      console.log('----------->>>', error);
    }
  };

  const handleSelectedTabValue = async (tabId, index) => {
    setSelectedTab(index);
    await getAllUsersData(tabId);
  };

  useEffect(() => {
    getAllUsersData('AdminVerification');
  }, []);

  const handleAcceptKyc = async (level, userId) => {
    try {
      const response = await adminInstance().post('api/admin/acceptUserKyc', {
        userId: userId,
        level: level,
      });
      const { msg, code } = response?.data;
      if (code === 200) {
        await getAllUsersData(selectedTab);
        setDetailPopup(false);
        toast.success(msg);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRejectKyc = async (level, userId) => {
    try {
      const response = await adminInstance().post('api/admin/rejectUserKyc', {
        userId: userId,
        level: level,
      });
      const { msg, code } = response?.data;
      if (code === 200) {
        await getAllUsersData(selectedTab);
        setDetailPopup(false);
        toast.success(msg);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleViewImage = (imgUrl) => {
    setShowImage(imgUrl);
  };
  return (
    <Fragment>
      <Breadcrumb title='KYC Requests' parent='User' />

      <Container fluid={true}>
        <div className='admin-card'>
          <Card>
            <CardHeader>
              <h5>KYC Requests</h5>
            </CardHeader>
            <CardBody>
              <Tabs selectedIndex={selectedTab}>
                <TabList className='nav nav-tabs tab-coupon pt-2'>
                  {tabs.map((tab, index) => (
                    <Tab
                      key={tab.id}
                      className='nav-link'
                      onClick={() => handleSelectedTabValue(tab.id, index)}
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
              <div
                id='batchDelete'
                className='category-table user-list order-table coupon-list-delete'
              >
                {!loading ? (
                  // usersLists.length > 0 && (
                  <ListDataTable
                    multiSelectOption={true}
                    myData={usersLists}
                    pageSize={10}
                    pagination={true}
                    class='-striped -highlight'
                    setUsersLists={setUsersLists}
                    selectedTab={selectedTab}
                    handleDetailPopupOpen={handleDetailPopupOpen}
                  />
                ) : (
                  // )
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>

      <Modal show={detailPopup} onHide={handleDetailPopupClose} size='xl'>
        {showImage ? (
          <div id='myNav' class='overlay w-100'>
            <div className='w-100 d-flex justify-content-end'>
              <CgClose
                className='mt-3 mx-3 close-image-show'
                onClick={() => setShowImage('')}
              />
            </div>
            <div class='overlay-content'>
              <img src={showImage} className='img-fluid' alt='preview-img' />
            </div>
          </div>
        ) : (
          <Modal.Body>
            {specificUserKycData &&
            specificUserKycData?.kycDetails?.status === 'completed' ? (
              <div className='level-wrapper'>
                <div className='d-flex align-items-center justify-content-between'>
                  <h4 className='text-dark'>Level 1</h4>
                  {specificUserKycData?.kycDetails?.isVerified && (
                    <p className='text-success'>Approved</p>
                  )}
                </div>
                <Form>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>First Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.firstName}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Last Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.lastName}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Address</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.address}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Zip Code</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.zipCode}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Phone</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.phone}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>DOB</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={formatDate(
                        specificUserKycData?.kycDetails?.dateOfBirth
                      )}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Country</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.country}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>Country Code</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.countryCode}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>state</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.state}
                      readOnly={true}
                    />
                  </Form.Group>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='text-dark'>City</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='name@example.com'
                      value={specificUserKycData?.kycDetails?.city}
                      readOnly={true}
                    />
                  </Form.Group>
                </Form>

                {specificUserKycData?.kycDetails?.isVerified ? (
                  <div className='d-flex align-items-center justify-content-between'></div>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        className='btn btn-success mt-2'
                        onClick={() =>
                          handleAcceptKyc('level1', specificUserKycData?._id)
                        }
                      >
                        Accept
                      </Button>
                      <Button
                        className='btn btn-danger mt-2'
                        onClick={() =>
                          handleRejectKyc('level1', specificUserKycData?._id)
                        }
                      >
                        Reject
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              specificUserKycData?.kycDetails?.status === 'rejected' && (
                <div className='d-flex align-items-center justify-content-between'>
                  <h4 className='text-dark'>Level 1</h4>
                  <p className='text-danger fs-bold'>Rejected</p>
                </div>
              )
            )}
            <div className='mt-2 level-wrapper '>
              {specificUserKycData &&
              specificUserKycData?.identityDocuments?.status === 'completed' ? (
                <div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='text-dark'>Level 2</h4>
                    {specificUserKycData?.identityDocuments?.isVerified && (
                      <p className='text-success'>Approved</p>
                    )}
                  </div>
                  <div>
                    {specificUserKycData?.identityDocuments && (
                      <div className='file-preview text-center'>
                        {specificUserKycData?.identityDocuments?.type ===
                        'application/pdf' ? (
                          <div className='pdf-preview'>
                            {/* Show PDF icon */}
                            <a
                              href={
                                specificUserKycData?.identityDocuments?.fileUrl
                              }
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <PdfIcon />
                              Open PDF
                            </a>
                          </div>
                        ) : (
                          <div className='image-preview- text-center'>
                            <img
                              src={
                                specificUserKycData?.identityDocuments?.fileUrl
                              }
                              onClick={() =>
                                handleViewImage(
                                  specificUserKycData?.identityDocuments
                                    ?.fileUrl
                                )
                              }
                              className='rounded-4'
                              alt='Uploaded Preview'
                              height='100px'
                              width='100px'
                            />
                          </div>
                        )}
                      </div>
                    )}

                    <div>
                      {specificUserKycData?.identityDocuments?.isVerified ? (
                        <div></div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            className='btn btn-success mt-2'
                            onClick={() =>
                              handleAcceptKyc(
                                'level2',
                                specificUserKycData?._id
                              )
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            className='btn btn-danger mt-2'
                            onClick={() =>
                              handleRejectKyc(
                                'level2',
                                specificUserKycData?._id
                              )
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                specificUserKycData?.identityDocuments?.status ===
                  'rejected' && (
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='text-dark'>Level 2</h4>
                    <p className='text-danger fs-bold'>Rejected</p>
                  </div>
                )
              )}
            </div>
            <div className='mt-2 level-wrapper '>
              {specificUserKycData &&
              specificUserKycData?.addressDocuments?.status === 'completed' ? (
                <div>
                  <h4 className='text-dark'>Level 3</h4>
                  <div>
                    {specificUserKycData?.addressDocuments && (
                      <div className='file-preview'>
                        {specificUserKycData?.addressDocuments?.type ===
                        'application/pdf' ? (
                          <div className='pdf-preview'>
                            {/* Show PDF icon */}
                            <a
                              href={
                                specificUserKycData?.addressDocuments?.fileUrl
                              }
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <PdfIcon />
                              Open PDF
                            </a>
                          </div>
                        ) : (
                          <div className='image-preview- text-center'>
                            <img
                              src={
                                specificUserKycData?.addressDocuments?.fileUrl
                              }
                              onClick={() =>
                                handleViewImage(
                                  specificUserKycData?.identityDocuments
                                    ?.fileUrl
                                )
                              }
                              alt='Uploaded Preview'
                              height='100px'
                              width='100px'
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {specificUserKycData?.addressDocuments?.isVerified ? (
                        <div className='d-flex align-items-center justify-content-between'>
                          <h4 className='text-dark'>Level 3</h4>
                          <p className='text-success'>Approved</p>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            className='btn btn-success mt-2'
                            onClick={() =>
                              handleAcceptKyc(
                                'level3',
                                specificUserKycData?._id
                              )
                            }
                            style={{
                              backgroundColor: '#007bff',
                              color: '#fff',
                              border: 'none',
                              padding: '10px 20px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                          >
                            Accept
                          </Button>
                          <Button
                            className='btn btn-danger mt-2'
                            onClick={() =>
                              handleRejectKyc(
                                'level3',
                                specificUserKycData?._id
                              )
                            }
                            style={{
                              color: '#fff',
                              border: 'none',
                              padding: '10px 20px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              fontWeight: 'bold',
                            }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                specificUserKycData?.addressDocuments?.status ===
                  'rejected' && (
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='text-dark'>Level 3</h4>
                    <p className='text-danger fs-bold'>Rejected</p>
                  </div>
                )
              )}
            </div>
            <div className='mt-2 level-wrapper'>
              {specificUserKycData &&
              specificUserKycData?.financialDocuments?.status ===
                'completed' ? (
                <div>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='text-dark'>Level 4</h4>
                    {specificUserKycData?.financialDocuments?.isVerified && (
                      <p className='text-success'>Approved</p>
                    )}
                  </div>
                  <div className='text-center'>
                    {specificUserKycData?.financialDocuments && (
                      <div className='file-preview'>
                        {specificUserKycData?.financialDocuments?.type ===
                        'application/pdf' ? (
                          <div className='pdf-preview'>
                            {/* Show PDF icon */}
                            <a
                              href={
                                specificUserKycData?.financialDocuments?.fileUrl
                              }
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <PdfIcon />
                              Open PDF
                            </a>
                          </div>
                        ) : (
                          <div className='image-preview- text-center'>
                            <img
                              src={
                                specificUserKycData?.financialDocuments?.fileUrl
                              }
                              onClick={() =>
                                handleViewImage(
                                  specificUserKycData?.identityDocuments
                                    ?.fileUrl
                                )
                              }
                              alt='Uploaded Preview'
                              height='100px'
                              width='100px'
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {specificUserKycData?.financialDocuments?.isVerified ? (
                        <div></div>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            className='btn btn-success mt-2'
                            onClick={() =>
                              handleAcceptKyc(
                                'level4',
                                specificUserKycData?._id
                              )
                            }
                          >
                            Accept
                          </Button>
                          <Button
                            className='btn btn-danger mt-2'
                            onClick={() =>
                              handleRejectKyc(
                                'level4',
                                specificUserKycData?._id
                              )
                            }
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                specificUserKycData?.financialDocuments?.status ===
                  'rejected' && (
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className='text-dark'>Level 4</h4>
                    <p className='text-danger fs-bold'>Rejected</p>
                  </div>
                )
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </Fragment>
  );
};
const PdfIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='35'
      fill='red'
      class='bi bi-filetype-pdf'
      viewBox='0 0 16 16'
    >
      <path
        fill-rule='evenodd'
        d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
      />
    </svg>
  );
};

export default Kyc_Request_List;
