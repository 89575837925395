import React, { Fragment } from "react";
import LoginTabset from "./loginTabset";
import Slider from "react-slick";
import stats from "../../assets/images/dashboard/login-logo.svg";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Redirect } from "react-router-dom";

const Login = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
  };
  return (
    <Fragment>
      {!localStorage.getItem("ad@#t") ? (
        <div className="page-wrapper user-login-page">
          <div className="authentication-box">
            <Container>
              <Row>
                <Col className="col-md-5 p-0 card-left">
                  <Card className="bg-primary login-card">
                    <div className="svg-icon svg-circle-icon">
                      <img alt="" src={stats} className="Img-fluid" />
                    </div>
                    <Slider className="single-item" {...settings}>
                      <div>
                        <div>
                          <h3>Welcome to Winpeer</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy.
                          </p>
                        </div>
                      </div>
                      {/* <div>
                        <div>
                          <h3>Welcome to Winpeer</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy.
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <h3>Welcome to Winpeer</h3>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy.
                          </p>
                        </div>
                      </div> */}
                    </Slider>
                  </Card>
                </Col>
                <Col className="col-md-7 p-0 card-right">
                  <Card className="tab2-card">
                    <CardBody className="loging-card-body">
                      <LoginTabset />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* <a
							href="https://Versuz-react.vercel.app/"
							target="_blank"
							rel="noreferrer"
							className="btn btn-primary back-btn"
						>
							<ArrowLeft />
							back
						</a> */}
            </Container>
          </div>
        </div>
      ) : (
        <Redirect to="/dashboard" />
      )}
    </Fragment>
  );
};

export default Login;
