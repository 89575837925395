import React, { Fragment, useState } from "react";
// import Breadcrumb from "../../common/breadcrumb";
// import CKEditors from "react-ckeditor-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RaritykValidationYup } from "../../../utils/authValidation";
import { adminInstance } from "../../../config/axios";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom'
import {
	Card,
	CardBody,
	// CardHeader,
	Col,
	Container,
	Form,
	FormGroup,
	Row,
	Button,
  Label,
} from "reactstrap";
// import one from "../../../assets/images/pro3/1.jpg";
// import user from "../../../assets/images/user.png";

const AddRarityForm = ({ afterPaste, onBlur, onChange , payload}) => {
  // console.log('payload on form', payload)
  const history = useHistory()
    const {
        register,
        //setValue,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({
        //  mode: "onBlur",
          defaultValues: payload || {},
        resolver: yupResolver(RaritykValidationYup()),
      });

      const [editData, setEditData] = useState();
      // const [image, setImage] = useState();
    
      const onSubmitHandler = async data => {
    
        // const formData = new FormData()
        // formData.append('file', data?.logo[0])
        // delete data.logo
        // Object.keys(data).map(el => formData.append(el, data[el]))
        try {
           const { _id } = payload || {}
          if (Object.keys(errors).length === 0) {
            if (_id) {
              const response = await adminInstance().put(`/api/admin/updateRarity/${ _id }`,data);
              const { code, msg } = response.data;
              setEditData({ ...editData, isUpdate: true });
              if (code === 200) {
                toast.success(msg);
                reset();
                history.push("/products/rarity-list");
              } else {
                toast.error(msg);
              }
            } else {
              
              //  console.log('i am in add condition')
            const response = await adminInstance().post(
              '/api/admin/craeteRarity',data
              // formData
            )
            const { code, msg, rarityData } = response.data
            console.log('rarityData', rarityData)
            if (code === 200) {
              reset();
              toast.success(msg);
              history.push('/products/rarity-list')
            } else {
              toast.error(msg)
            }
          }
          }
        } catch (error) {
          console.log('error', error)
        }
      }

      

	return (
		<Fragment>
             <Form
              className="needs-validation user-add"
             onSubmit={handleSubmit(onSubmitHandler)}
           >
			{/* <Breadcrumb title="Rarity" parent="Rarity" /> */}
			<Container fluid={true}>
				<Row className="product-adding">
					<Col xl="6">
						<Card>
							{/* <CardHeader>
								<h5>Add Rarity</h5>
							</CardHeader> */}
							<CardBody>
								<div className="digital-add needs-validation">
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span>  Name:
										</Label>
                      <input
                      {...register("name")}
                      className="form-control"
                      type="text"
                      // defaultValue={payload.name || ""}
                      />
									</FormGroup>
									<FormGroup>
										<Label className="col-form-label pt-0">
											<span>*</span> ColorName/ColorCode  :
										</Label>
                    <input
                        {...register("color")}
                        className="form-control"
                        type="color"
                        // defaultValue={payload.color || ""}
                        />
									
									</FormGroup>
								</div>
              <FormGroup>
                <div className="product-buttons d-flex justify-content-start text-center mt-5">
                    <Button type="submit" color="primary" className="main-btn">
                      SUBMIT
                    </Button>
                </div>
            </FormGroup>
							</CardBody>
						</Card>
					</Col>
					
				</Row>
			</Container>
            </Form>
		</Fragment>
	);

};


export default AddRarityForm;

	




