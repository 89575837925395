import React, { Fragment, useState } from "react";
import Breadcrumb from "./common/breadcrumb";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminInstance } from "../../src/config/axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Label,
} from "reactstrap";
import { filterDateObj } from "../utils/multiuseFunctions";
import { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { getAdminBalance, getTotalAmounts } from "../reducers/adminReducers";
import { useDispatch, useSelector } from "react-redux";
const Dashboard = () => {
  const result = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { loginAdmin, adminWallet, totalAmounts } = result || {};
  const { role } = loginAdmin || [];
  let readRole = role?.some((r) =>
    ["suUsers", "superAdmin", "readDashboard"].includes(r)
  );

  useEffect(() => {
    dispatch(getAdminBalance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTotalAmounts());
  }, [dispatch]);

  const initialState = {
    type: "last7day",
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };

  const [payload, setPayload] = useState(initialState);
  const initialData = {
    deposit: "",
    totalDeposit: "",
    dates: "",
    totalunboxed: "",
    unboxed: "",
    shiprequests: "",
    totalShip: "",
    netProfit: "",
    pnlData: "",
  };
  const [data, setData] = useState(initialData);

  const fillterArray = [
    { today: "Today" },
    { yesterday: "Yesterday" },
    { last7day: "Last 7 days" },
    { last28day: "last  28 days" },
    { last90day: "Last 90 days" },
    { yeartodate: "Year to date" },
    { monthtodate: "Month to date" },
    { weektodate: "Week to date" },
    { custom: "custom" },
  ];

  const handleDateChange = (name) => (val) => {
    const copy = { ...payload };
    copy[name] = val;
    if (copy.rangeStartDate < copy.rangeEndDate) {
      copy["type"] = "custom";
    }
    setPayload(copy);
  };

  const getStatsData = async () => {
    try {
      const filterData = filterDateObj(payload);
      const { summaryDate } = filterData || {};
      const response = await adminInstance().post(
        "api/admin/statsSummary",
        filterData
      );
      const {
        totalDepositeData,
        total,
        totalWithDrowData,
        total2,
        totalunboxed,
        total3,
        totalShip,
        total4,
        totalAllShipsItems,
        total5,
        totalPriceUnboxed,
        total6,
        totalItemsPriceUnBoxed,
        total7,
        total8,
        totalNetProfit,
        total9,
        totalPnL,
        totalShipReq,
        total10,
        totalResellPercent,
        total11,
        totalAverageOrder,
        total12,
        retentionArray,
        total13,
        conversionRateArr,
        totalRatPre,
        churnRateArray,
        total14,
        totalWagered,
        total15,
        totalProductChances,
        total16,
        totalBestDeposit,
        total17,
        activeUserCount,
        total18,
        totalArpu,
        total20,
        totalConversionTwo,
        total21,
        averagebox,
        total19,
        total22,
        totalNewUsers,
        totalRequestedItemsShip,
        total23,
        totalprofittPercentage,
        profittPercentage,
        totalavgDeposit,
        avgDeposit,
      } = response.data;
      setData({
        deposit: totalDepositeData,
        totalDeposit: total,
        withdraw: totalWithDrowData,
        totalwithdraw: total2,
        dates: summaryDate,
        unboxed: totalunboxed,
        totalunboxed: total3,
        shiprequests: totalShip,
        totalShip: total4,
        shipAllRequest: totalAllShipsItems,
        totalAllShip: total5,
        unBoxedPrice: totalPriceUnboxed,
        totalUnboxedPrice: total6,
        unBoxedItemsPrice: totalItemsPriceUnBoxed,
        totalUnboxedItemsPrice: total7,
        netProfit: total8,
        totalNetProfitDisp: totalNetProfit,
        pnlData: totalPnL,
        pnlTotal: total9,
        allshipReq: totalShipReq,
        totalShipReq: total10,
        totalResell: total11,
        resellPercent: totalResellPercent,
        totalAvgOrder: total12,
        totalAvgArr: totalAverageOrder,
        retentionArray,
        totalRetentional: total13,
        totalChurn: total14,
        churnRateArray,
        conversionRateArr,
        totalRatPre,
        totalWageredArr: totalWagered,
        totalWageredAmount: total15,
        totalProductChances,
        totalHousAdv: total16,
        bestDepositArray: totalBestDeposit,
        totalbestDeposit: total17,
        activeUserCount,
        totalActiveUser: total18,
        averageboxArray: averagebox,
        totalAverage: total19,
        totalArpuValue: total20,
        arpuArray: totalArpu,
        conversionTwoArray: totalConversionTwo,
        totalConversionTwo: total21,
        totalNewUsers,
        newUsersCount: total22,
        totalRequestedItemsShipArray: totalRequestedItemsShip,
        totalShipCounts: total23,
        totalDepositAvg: totalavgDeposit,
        avgDepositArray: avgDeposit,
        totalprofittPercentage,
        profittPercentage,
      });
    } catch (error) {}
  };
  useEffect(() => {
    getStatsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  // const pieOptions = {
  //   title: "",
  //   pieHole: 1,
  //   slices: [
  //     {
  //       color: "#083248",
  //     },
  //     {
  //       color: "#13c9ca",
  //     },
  //     {
  //       color: "#03D1FF",
  //     },
  //   ],
  //   tooltip: {
  //     showColorCode: false,
  //   },
  //   chartArea: { left: 0, top: 10, width: "360px", height: "100%" },
  //   legend: "none",
  // };
  const LineOptions = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
      viewWindow: {
        min: -0.1,
      },
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
      viewWindow: {
        min: -0.1,
      },
    },
    colors: ["#03D1FF"],
    legend: "none",
  };

  const [showCustom, setShowCustom] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const handleDateFilter = (val, data) => {
    if (val === "custom") {
      setShowCustom(true);
    } else {
      setShowCustom(false);
    }
    setPayload({ ...payload, type: val });
  };
  const [dataTable, setDataTable] = useState();
  const getStatsSummaryTable = async () => {
    const filterData = filterDateObj(payload);
    setSpinner(true);
    const response = await adminInstance().post(
      "api/admin/statsSummaryTable",
      filterData
    );
    if (response?.data?.code === 200) {
      setSpinner(false);
    }
    const {
      topBoxByQuantity,
      topItemsUnboxed,
      requestedItemByPrice,
      depositByUser,
      boxOpenByUser,
      depositeUserMagnitude,
      boxOpenedByGoverante,
      depositByGovernate,
      depositeUserMagnitudeNew,
    } = response?.data;
    setDataTable({
      topBoxByQuantity: topBoxByQuantity,
      topItemsUnboxed: topItemsUnboxed,
      requestedItemByPrice: requestedItemByPrice,
      depositByUser: depositByUser,
      boxOpenByUser: boxOpenByUser,
      depositeUserMagnitude: depositeUserMagnitude,
      boxOpenedByGoverante: boxOpenedByGoverante,
      depositByGovernate: depositByGovernate,
      depositeUserMagnitudeNew,
    });
  };
  useEffect(() => {
    getStatsSummaryTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const [adminBalance, setAdminBalance] = useState(adminWallet.amount);
  const [isRotating, setIsRotating] = useState(false);

  const handleRefresh = () => {
    setIsRotating(true);
    setTimeout(() => {
      setAdminBalance(adminWallet.amount);
      setIsRotating(false);
    }, 500);
  };

  return (
    <Fragment>
      <Breadcrumb title="Dashboard" parent="Dashboard" />
      {readRole ? (
        <Container className="dashboard-page" fluid={true}>
          <Row>
            <Col xl="12 xl-50" md="12">
              <div className="d-flex justify-content-between">
                <select
                  class="category-search"
                  name="datetype"
                  value={payload.type}
                  onChange={(e) => handleDateFilter(e.target.value, "")}
                >
                  <option value="today"> Today </option>
                  <option value="yesterday"> Yesterday </option>
                  <option value="last7day">Last 7 days </option>
                  <option value="last28day"> last 28 days </option>
                  <option value="last90day"> Last 90 days </option>
                  <option value="weektodate"> Week to date </option>
                  <option value="monthtodate"> Month to date </option>
                  <option value="yeartodate"> Year to date </option>
                  <option value="custom"> Custom </option>
                </select>

                <div className="d-flex align-items-center admin-balance">
                  <p className="mb-4 pr-2 font-weight-bold">Admin Balance</p>
                  <div className="text-dark border category-search d-flex gap-2 align-items-center">
                    <span className="m-0 pr-2 text-center font-weight-bold">
                      ${adminBalance ? adminBalance : ""}
                    </span>
                    <div
                      className={`refreshicon ${
                        isRotating ? "refreshIcon" : ""
                      }`}
                      onClick={handleRefresh}
                      style={{ cursor: "pointer" }}
                    >
                      <RefreshIcon />
                    </div>
                  </div>
                </div>
              </div>
              {showCustom && (
                <div className="custom-date">
                  <div className="custom-date-content">
                    <Label>Start Date</Label>
                    <DatePicker
                      selected={payload.rangeStartDate}
                      onChange={handleDateChange("rangeStartDate")}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="custom-date-content">
                    <Label>End Date</Label>
                    <DatePicker
                      selected={payload.rangeEndDate}
                      maxDate={new Date()}
                      onChange={handleDateChange("rangeEndDate")}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL DEPOSITS</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              totalAmounts.length > 0
                                ? totalAmounts.totalDeposits
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(totalAmounts.totalDeposits
                            ? totalAmounts.totalDeposits.toFixed(2)
                            : 0) || 0}{" "}
                          $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        DEPOSITS{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Amount of money that users have deposited</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {(data?.totalDeposit
                          ? data.totalDeposit.toFixed(2)
                          : 0) || 0}{" "}
                        $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL WITHDRAWS</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              totalAmounts?.length > 0
                                ? totalAmounts.totalWithdrawals
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {totalAmounts.totalWithdrawals?.toFixed(2) || 0} $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        WITHDRAWS{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p> Amount of money that users have withdrawn</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalwithdraw?.toFixed(2) || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="3 xl-50" md="6">
            <Card className="card-graph order-graph sales-carousel">
              <CardHeader className="header-card">
                <h6>TOTAL VALUE OF ITEMS REQUESTED FOR SHIPPING</h6>
                <Row>
                  <Col className="col-6">
                    <div className="small-chartjs">
                      <div
                        className="flot-chart-placeholder"
                        id="simple-line-chart-sparkline-3"
                      >
                        <Chart
                          height={"60px"}
                          chartType="LineChart"
                          loader={<div>Loading Chart</div>}
                          data={
                            data?.shiprequests?.length > 0 ?  data.shiprequests :
                            [
                            ["x", "$"],
                            [0,0]
                          ]}
                          options={LineOptions}
                          legend_toggle
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="col-6">
                    <div className="value-graph">
                      <h3>
                      {data?.totalShip || 0} $
                      </h3>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Media>
                  <Media body>
                    <p>REQUESTED FOR SHIPPING {fillterArray.filter((el)=>el[payload?.type])[0][payload?.type]}</p>
                    <p>Total sum of requested items prices</p>
                    <h5 className="mb-0" style={{color: "#000 "}}>{data?.totalShip || 0} $</h5>
                    <p>{data?.dates}</p>
                   
                  </Media>
                </Media>
              </CardBody>
            </Card>
          </Col> */}
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Total sum of requested items prices</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalRequestedItemsShipArray?.length > 0
                                ? data.totalRequestedItemsShipArray
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalShipCounts || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        REQUESTED FOR SHIPPING{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Total sum of requested items prices</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalShipCounts || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL BOXES UNBOXED</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.unboxed?.length > 0
                                ? data.unboxed
                                : [
                                    ["x", "Number"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalunboxed || 0}$</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        BOXES UNBOXED{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Number of opened boxes</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalunboxed || 0}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>NET PROFIT</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalNetProfitDisp?.length > 0
                                ? data.totalNetProfitDisp
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(data?.netProfit ? data.netProfit?.toFixed(2) : 0) ||
                            0}{" "}
                          $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        TOTAL NET PROFIT{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        TOTAL DEPOSITS - TOTAL WITHDRAWS - TOTAL VALUE OF ITEMS
                        REQUESTED
                      </p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL UNBOXED TURNOVER</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.unBoxedPrice?.length > 0
                                ? data.unBoxedPrice
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalUnboxedPrice?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        UNBOXED TURNOVER{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Total sum of opened boxes prices</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalUnboxedPrice?.toFixed(2) || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL UNBOXED ITEMS PRICE</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.unBoxedItemsPrice?.length > 0
                                ? data.unBoxedItemsPrice
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {data?.totalUnboxedItemsPrice?.toFixed(2) || 0} $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        UNBOXED ITEMS PRICE{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Total sum of items prices</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalUnboxedItemsPrice?.toFixed(2) || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Profit and loss PnL</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.pnlData?.length > 0
                                ? data.pnlData
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.pnlTotal?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Pnl{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        TOTAL DEPOSITS - TOTAL UNBOXED TURNOVER + TOTAL UNBOXED
                        ITEMS PRICE
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.pnlTotal?.toFixed(2) || 0}$
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Total shipments requested</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.allshipReq?.length > 0
                                ? data.allshipReq
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalShipReq || 0} </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total shipments requested{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p></p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalShipReq || 0}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Resell Percentage</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.resellPercent?.length > 0
                                ? data.resellPercent
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalResell?.toFixed(2) || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Resell Percent{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>resold items / won items</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalResell?.toFixed(2) || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6> Average Box Price</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalAvgArr?.length > 0
                                ? data.totalAvgArr
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalAvgOrder?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Average Box Price{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>TOTAL UNBOXED TURNOVER / TOTAL BOXES UNBOXED </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalAvgOrder?.toFixed(2) || 0}$
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Bets-to-Deposits</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalWageredArr?.length > 0
                                ? data.totalWageredArr
                                : [
                                  ["x", "$"],
                                  [0, 0],
                                ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalWageredAmount || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Bets-to-Deposits{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                          payload?.type
                          ]
                        }
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalWageredAmount || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>HOUSE ADVANTAGE</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalProductChances?.length > 0
                                ? data.totalProductChances
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalHousAdv?.toFixed(2) || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        House Advantage{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        {" "}
                        From all boxes, what is the percentage of item price is
                        {" < "}to box price
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalHousAdv?.toFixed(2) || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Churn Rate</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.churnRateArray?.length > 0
                                ? data.churnRateArray
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalChurn?.toFixed(2) || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total churn rate{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Inactive users / total users </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalChurns?.toFixed(2) || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Retention rate</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.retentionArray?.length > 0
                                ? data.retentionArray
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalRetentional?.toFixed(2) || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total retention rate{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        users that deposited at least twice / users that
                        deposited
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalRetentional?.toFixed(2) || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Average Order Value</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.avgOrderArray?.length > 0
                                ? data.avgOrderArray
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalAvgOrder?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Average Order Value{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>TOTAL UNBOXED TURNOVER / TOTAL BOXES UNBOXED</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalAvgOrder?.toFixed(2) || 0}$
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Sign Up Conversion Rate.</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.conversionRateArr?.length > 1
                                ? data.conversionRateArr
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalRatPre || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Sign Up Conversion Rate{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p> Visits to registration</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalRatPre || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>First-time deposit rate</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.conversionTwoArray?.length > 1
                                ? data.conversionTwoArray
                                : [
                                    ["x", "Number"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalConversionTwo?.toFixed(2) || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        First-time deposit rate
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>Registrations to deposit</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalConversionTwo?.toFixed(2) || 0} %
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Deposits-to-Boxing</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.bestDepositArray?.length > 0
                                ? data.bestDepositArray
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalbestDeposit || 0} %</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Deposits-to-Boxing{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>TOTAL UNBOXED TURNOVER / TOTAL DEPOSITS</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalbestDeposit || 0}%
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
        */}
            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Active Users</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.activeUserCount?.length > 0
                                ? data.activeUserCount
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalActiveUser || 0}</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Active Users{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        Users who made at least one deposit within this time
                        frame
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalActiveUser || 0}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Average boxing per</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.averageboxArray?.length > 0
                                ? data.averageboxArray
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalAverage || 0}</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Total Average boxing per{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>
                        Number of opened boxes / Number of users that opened
                        those boxed
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalAverage || 0}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>ARPU</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3">
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.arpuArray?.length > 0
                                ? data.arpuArray
                                : [
                                    ["x", "Number"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalArpuValue?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        Active Users{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      <p>NET PROFIT / Active Users</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalArpuValue?.toFixed(2) || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}

            <Col xl="4 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>New Users</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.totalNewUsers?.length > 0
                                ? data.totalNewUsers
                                : [
                                    ["x", "Number"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.newUsersCount?.toFixed(2) || 0}</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>New Users Last 30 days</p>
                      <p>Total users within this time frame</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.newUsersCount || 0}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>Average Deposit Value</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3">
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.avgDepositArray?.length > 0
                                ? data?.avgDepositArray
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>{data?.totalDepositAvg?.toFixed(2) || 0} $</h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        AVERAGE DEPOSIT{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }{" "}
                      </p>
                      <p>TOTAL DEPOSITS / ACTIVE USERS</p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalDepositAvg || 0} $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
            {/* <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>PROFIT PERCENTAGE</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3">
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.profittPercentage?.length > 0
                                ? data?.profittPercentage
                                : [
                                    ["x", "%"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {data?.totalprofittPercentage?.toFixed(2) || 0} %
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>Total PROFIT PERCENTAGE</p>
                      <p>
                        {" "}
                        ( NET PROFIT [for specific time frame] / TOTAL DEPOSITS
                        [for specific time frame] ) * 100
                      </p>
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {data?.totalprofittPercentage || 0} %
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col> */}
          </Row>

          <Row>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>Top Boxes Opened</h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTable?.topBoxByQuantity.length > 0 &&
                            dataTable?.topBoxByQuantity.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.boxData?.name}
                                </td>
                                <td className="digits">{el?.quantity}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>UNBOXED ITEMS </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTable?.topItemsUnboxed.length > 0 &&
                            dataTable?.topItemsUnboxed.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.product?.name}
                                </td>
                                <td className="digits">
                                  {el?.product?.price?.toFixed(2)} $
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>Requested ITEMS </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable?.requestedItemByPrice.length > 0 &&
                            dataTable?.requestedItemByPrice.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.product?.name}
                                </td>
                                <td className="digits">
                                  {el?.product?.price?.toFixed(2)} $
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>Deposit By User </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Total Deposit Amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {dataTable?.depositByUser.length > 0 &&
                            dataTable?.depositByUser.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.user?.username}
                                </td>
                                <td className="digits">{el?.totalDeposit?.toFixed(2)} $</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>DEPOSITS (BY ORDER OF Magnitude) </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Total Deposit Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataTable?.depositeUserMagnitudeNew.length > 0 &&
                          dataTable?.depositeUserMagnitudeNew.map((el) => (
                            <tr>
                              <td className="font-primary">
                                {el?.userId?.username}
                              </td>
                              <td className="digits">{el?.amount} $</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>Top Deposits </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name </th>
                            <th scope="col">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTable?.depositeUserMagnitude.length > 0 &&
                            dataTable?.depositeUserMagnitude.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.userId?.username}
                                </td>
                                <td className="digits">$ {el?.amountInUsd}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>Box Open By User </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    {spinner ? (
                      <div className="spinner-loader">
                        <Spinner
                          animation="border"
                          style={{ color: "#00FF86" }}
                        />
                      </div>
                    ) : (
                      <table className="table table-bordernone mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Total Box Open (Count)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataTable?.boxOpenByUser.length > 0 &&
                            dataTable?.boxOpenByUser.map((el) => (
                              <tr>
                                <td className="font-primary">
                                  {el?.user?.username}
                                </td>
                                <td className="digits">{el?.count}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>
                    DEPOSITS BY GOVERNORATE AND BOXES OPENED BY GOVERNORATE
                    STATUS
                  </h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl="3 xl-50" sm="6">
                      <div className="order-graph">
                        <p>DEPOSITS BY GOVERNORATE</p>

                        <div className="chart-block chart-vertical-center">
                          <Chart
                            width={"100%"}
                            height={"180px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={dataTable?.depositByGovernate}
                            options={pieOptions}
                            legend_toggle
                          />
                        </div>
                        <div className="order-graph-bottom">
                          {dataTable?.depositByGovernate.length > 0 &&
                            dataTable?.depositByGovernate
                              .slice(1, dataTable?.depositByGovernate.length)
                              .map((el) => (
                                <Media>
                                  <div className="order-color-primary"></div>
                                  <Media body>
                                    <p className="mb-0 ml-2">
                                      {el[0]}
                                      <span className="pull-right">
                                        {el[1]}
                                      </span>
                                    </p>
                                  </Media>
                                </Media>
                              ))}
                        </div>
                      </div>
                    </Col>

                    <Col xl="3 xl-50" sm="6">
                      <div className="order-graph sm-order-space">
                        <p>BOXES OPENED BY GOVERNORATE</p>
                        <div className="peity-chart-dashboard text-center">
                          <Chart
                            chartType="PieChart"
                            data={pieDataOpenBox}
                            options={pieOptions}
                            graph_id="PieChart"
                            width={"100%"}
                            height={"180px"}
                            legend_toggle
                          />
                        </div>
                        <div className="order-graph-bottom sales-location">
                          {dataTable?.boxOpenedByGoverante.length > 0 &&
                            dataTable?.boxOpenedByGoverante
                              .slice(1, dataTable?.boxOpenedByGoverante.length)
                              .map((el) => (
                                <Media>
                                  <div className="order-color-primary"></div>
                                  <Media body>
                                    <p className="mb-0 ml-2">
                                      {el?._id}
                                      <span className="pull-right">
                                        {el?.newcount}
                                      </span>
                                    </p>
                                  </Media>
                                </Media>
                              ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
      ) : (
        <h2>Welcome to admin Winpeer</h2>
      )}
    </Fragment>
  );
};
export default Dashboard;

const RefreshIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      style={{
        display: "inline-block",
        stroke: "currentColor",
        fill: "currentColor",
        width: "15px",
        height: "15px",
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M515.3 96C310.8 96 142.7 252.3 126.3 451.6H85.3C72.3 451.6 60.7 459.4 55.7 471.4 50.8 483.4 53.6 497.1 62.8 506.3L134.4 577.4C146.9 589.8 167 589.8 179.5 577.4L251.2 506.3C260.4 497.1 263.2 483.4 258.2 471.4 253.3 459.4 241.6 451.6 228.6 451.6H190.6C206.8 288.1 345.8 160 515.3 160 633.5 160 736.9 222.2 794.2 315.4 803.5 330.5 823.2 335.2 838.3 325.9 853.3 316.7 858 297 848.8 281.9 780.2 170.4 656.5 96 515.3 96Z"></path>
      <path d="M889.2 446.6C876.7 434.3 856.7 434.3 844.2 446.6L772.3 517.7C763 526.8 760.2 540.6 765.1 552.6 770.1 564.6 781.8 572.4 794.7 572.4H833.1C816.8 735.8 677.3 864 507 864 388.2 864 284.4 801.7 226.9 708.5 217.6 693.5 197.9 688.8 182.8 698.1 167.8 707.4 163.1 727.1 172.4 742.1 241.2 853.6 365.4 928 507 928 712.1 928 880.9 771.8 897.4 572.4H938.6C951.6 572.4 963.3 564.6 968.2 552.6 973.2 540.6 970.4 526.8 961.1 517.7L889.2 446.6Z"></path>
    </svg>
  );
};
