import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AddFaqForm from "./addFaqForm.js";
import { adminInstance } from '../../../config/axios'
import { useLocation } from "react-router-dom";
// import { getAllfaqCategoryData } from "../../../reducers/adminReducers";
// import { useDispatch } from "react-redux";
const Addfaq = () => {
    const initialObj = {
        title: "",
        description: '',
        selectCategory: ''
    };

    const [selectedOption, setSelectedOption] = useState([])
    // const [payload, setPayload] = useState({})
    // const [dd, setdd] = useState(false)
    const [payload, setPayload] = useState(initialObj);
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);

    }
    let query = useQuery();
    //const dispatch = useDispatch();
    let faqId = query.get('id');
    console.log("faqId=====>", faqId);
    const getSingleFaqCategoryData = async (id) => {
        try {
            const response = await adminInstance().get(`api/admin/getSingleFaqData/${id}`)
            const { code, faqData } = response.data;
            let blankArray = [];
            // console.log('data', data)
            if (code === 200) {
                setPayload(faqData)
                faqData?.forEach((el) =>
                    blankArray.push({ value: el._id, label: el.name }))
            }
            setSelectedOption(blankArray);
        } catch (error) {
            console.log('----------->aaa>>', error)
        }
    }

    const [faqcategories, setFaqCategories] = useState([])
    const getCategory = async () => {
        const res = await adminInstance().get('/api/admin/getAllfaqCategoryData');
        console.log("Check Faq DaAT", res?.data?.allFaqData);
        // setFaqCategories(res?.data?.allFaqData)
        const { allFaqData, code } = res.data;
        let blankArray = [];
        if (code === 200) {
            allFaqData?.forEach((el) =>
                blankArray.push({ value: el._id, label: el.name }))
        }
        console.log('vxcvbchvgjgvgvfvuygf', blankArray)
        setFaqCategories(blankArray);
    }

    useEffect(() => {
        getCategory();
    }, [])
    console.log("askgmnsjdgsad====>sdfdfd", payload)
    const blank = {}

    useEffect(() => {
        if (faqId) {
            getSingleFaqCategoryData(faqId)
        }
    }, [faqId])

    console.log('pauloaododi', payload)

    return (
        <Fragment>
            <Breadcrumb title="FAQ" parent="Faq" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                {faqId ? payload?._id && <h5> Update Faq </h5> : <h5> Add  Faq  </h5>}
                                {/* <h5> Add  Faq  </h5> */}
                            </CardHeader>
                            <CardBody>
                                {/* <AddFaqForm ></AddFaqForm> */}
                                {console.log("faq Changedfsdscugasgubcknx =========>", faqId)}
                                {console.log("faq  payload=========>", payload)}
                                {faqId ? payload?._id && <AddFaqForm payload={payload}
                                    faqcategories={faqcategories} selectedOption={selectedOption} /> : <AddFaqForm payload={blank} faqcategories={faqcategories} selectedOption={selectedOption} />}

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default Addfaq;

