import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AddBrandForm from "./addBrandForm.js";
import { adminInstance } from '../../config/axios'
import { useLocation } from "react-router-dom";
const AddBrand = () => {
	const initialObj = {
		name: "",
		image: "",
		description: "",
	};

	const [payload, setPayload] = useState(initialObj);
	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);

	}

	let query = useQuery();
	let brandId = query.get('id');



	const getSingleBrandyData = async (id) => {
		try {
			const response = await adminInstance().get(`api/admin/getBrandDetailById/${id}`)
			const { code, brandData } = response.data;
			if (code === 200) {
				setPayload(brandData);
			}
		} catch (error) {
			console.log('----------->aaa>>', error)
		}
	}

	const blank = {}


	useEffect(() => {
		getSingleBrandyData(brandId)
		// setSelectData();
	}, [brandId])

	return (
		<Fragment>
			<Breadcrumb title="Brand" parent="Brand" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5> Add Brand </h5>
							</CardHeader>
							<CardBody>

								{brandId ? payload?._id && <AddBrandForm payload={payload} /> : <AddBrandForm payload={blank} />}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AddBrand;
